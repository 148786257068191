import { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabase';

interface Answer {
  id: string;
  question_id: string;
  answer_text: string;
  is_correct: boolean;
}

interface Question {
  id: string;
  topic_id: string;
  question_text: string;
  explanation: string;
  answers: Answer[];
}

interface Quiz {
  id?: string;
  title: string;
  description: string;
  time_limit: number;
  passing_score: number;
  questions: string[]; // Array of question IDs
}

export function useQuizBuilder() {
  const [saving, setSaving] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('questions')
        .select(`
          *,
          answers (*)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setQuestions(data || []);
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const saveQuiz = async (quiz: Quiz) => {
    setSaving(true);
    try {
      // Create the quiz
      const { data: quizData, error: quizError } = await supabase
        .from('quizzes')
        .insert([{
          title: quiz.title,
          description: quiz.description,
          time_limit: quiz.time_limit,
          passing_score: quiz.passing_score
        }])
        .select()
        .single();

      if (quizError) throw quizError;

      // Update questions with quiz_id
      const { error: updateError } = await supabase
        .from('questions')
        .update({ quiz_id: quizData.id })
        .in('id', quiz.questions);

      if (updateError) throw updateError;

      return quizData;
    } catch (error) {
      console.error('Error saving quiz:', error);
      throw error;
    } finally {
      setSaving(false);
    }
  };

  const updateQuiz = async (quiz: Quiz) => {
    if (!quiz.id) throw new Error('Quiz ID is required for updates');

    setSaving(true);
    try {
      // Update quiz details
      const { error: quizError } = await supabase
        .from('quizzes')
        .update({
          title: quiz.title,
          description: quiz.description,
          time_limit: quiz.time_limit,
          passing_score: quiz.passing_score
        })
        .eq('id', quiz.id);

      if (quizError) throw quizError;

      // First, remove quiz_id from all questions that were previously part of this quiz
      const { error: clearError } = await supabase
        .from('questions')
        .update({ quiz_id: null })
        .eq('quiz_id', quiz.id);

      if (clearError) throw clearError;

      // Then, update the new set of questions with this quiz_id
      const { error: updateError } = await supabase
        .from('questions')
        .update({ quiz_id: quiz.id })
        .in('id', quiz.questions);

      if (updateError) throw updateError;
    } catch (error) {
      console.error('Error updating quiz:', error);
      throw error;
    } finally {
      setSaving(false);
    }
  };

  return {
    saving,
    loading,
    questions,
    saveQuiz,
    updateQuiz,
    refreshQuestions: fetchQuestions
  };
}