import { useState, useEffect } from 'react';
import { FlashcardService } from '../services/FlashcardService';
import { Flashcard, Topic } from '../types/flashcard';
import { useAuthStore } from '../store/auth';

export function useFlashcards(topicId: string | undefined) {
  const [flashcards, setFlashcards] = useState<Flashcard[]>([]);
  const [topic, setTopic] = useState<Topic | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuthStore();
  const flashcardService = FlashcardService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Check authentication
        if (!user) {
          setError('Please sign in to view flashcards');
          return;
        }

        // Validate topic ID
        if (!topicId) {
          setError('No topic ID provided');
          return;
        }

        // Fetch topic details and flashcards in parallel
        const [topicData, flashcardsData] = await Promise.all([
          flashcardService.getTopicDetails(topicId),
          flashcardService.getFlashcardsForTopic(topicId)
        ]);

        setTopic(topicData);
        setFlashcards(flashcardsData);
      } catch (error: any) {
        console.error('Error fetching data:', error);
        setError(error.message || 'Failed to load flashcards');
        setFlashcards([]);
        setTopic(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [topicId, user]);

  return {
    flashcards,
    topic,
    loading,
    error
  };
}