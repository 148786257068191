import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cloud, RefreshCw, CheckCircle, AlertCircle, ArrowUpCircle, Code, Save, X } from 'lucide-react';
import { supabase } from '../../../lib/supabase';
import { useAuthStore } from '../../../store/auth';

interface EdgeFunction {
  name: string;
  lastUpdated: string;
  status: 'deployed' | 'failed' | 'deploying';
  code?: string;
}

export function EdgeFunctionsManager() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [functions, setFunctions] = useState<EdgeFunction[]>([
    {
      name: 'stripe-events-handler',
      lastUpdated: new Date().toISOString(),
      status: 'deployed',
      code: `import { serve } from 'https://deno.land/std@0.168.0/http/server.ts';
import { stripe } from '../_shared/stripe.ts';
import { corsHeaders } from '../_shared/cors.ts';

serve(async (req) => {
  // Handle CORS
  if (req.method === 'OPTIONS') {
    return new Response(null, { 
      status: 204,
      headers: corsHeaders 
    });
  }

  try {
    // Your function code here
    return new Response(
      JSON.stringify({ success: true }), 
      {
        headers: { ...corsHeaders, 'Content-Type': 'application/json' },
        status: 200
      }
    );
  } catch (error) {
    return new Response(
      JSON.stringify({ error: error.message }), 
      {
        headers: { ...corsHeaders, 'Content-Type': 'application/json' },
        status: 400
      }
    );
  }
});`
    },
    {
      name: 'create-checkout-session',
      lastUpdated: new Date().toISOString(),
      status: 'deployed',
      code: `import { serve } from 'https://deno.land/std@0.168.0/http/server.ts';
import { stripe } from '../_shared/stripe.ts';
import { corsHeaders } from '../_shared/cors.ts';

serve(async (req) => {
  if (req.method === 'OPTIONS') {
    return new Response(null, { 
      status: 204,
      headers: corsHeaders 
    });
  }

  try {
    const session = await stripe.checkout.sessions.create({
      // Your checkout session configuration
    });

    return new Response(
      JSON.stringify({ sessionId: session.id }), 
      {
        headers: { ...corsHeaders, 'Content-Type': 'application/json' },
        status: 200
      }
    );
  } catch (error) {
    return new Response(
      JSON.stringify({ error: error.message }), 
      {
        headers: { ...corsHeaders, 'Content-Type': 'application/json' },
        status: 400
      }
    );
  }
});`
    },
    {
      name: 'stripe-webhook',
      lastUpdated: new Date().toISOString(),
      status: 'deployed',
      code: `import { serve } from 'https://deno.land/std@0.168.0/http/server.ts';
import { stripe } from '../_shared/stripe.ts';
import { corsHeaders } from '../_shared/cors.ts';

serve(async (req) => {
  if (req.method === 'OPTIONS') {
    return new Response(null, { 
      status: 204,
      headers: corsHeaders 
    });
  }

  try {
    // Handle webhook event
    return new Response(
      JSON.stringify({ received: true }), 
      {
        headers: { ...corsHeaders, 'Content-Type': 'application/json' },
        status: 200
      }
    );
  } catch (error) {
    return new Response(
      JSON.stringify({ error: error.message }), 
      {
        headers: { ...corsHeaders, 'Content-Type': 'application/json' },
        status: 400
      }
    );
  }
});`
    },
    {
      name: 'cancel-subscription',
      lastUpdated: new Date().toISOString(),
      status: 'deployed',
      code: `import { serve } from 'https://deno.land/std@0.168.0/http/server.ts';
import { stripe } from '../_shared/stripe.ts';
import { corsHeaders } from '../_shared/cors.ts';

serve(async (req) => {
  if (req.method === 'OPTIONS') {
    return new Response(null, { 
      status: 204,
      headers: corsHeaders 
    });
  }

  try {
    // Cancel subscription logic
    return new Response(
      JSON.stringify({ success: true }), 
      {
        headers: { ...corsHeaders, 'Content-Type': 'application/json' },
        status: 200
      }
    );
  } catch (error) {
    return new Response(
      JSON.stringify({ error: error.message }), 
      {
        headers: { ...corsHeaders, 'Content-Type': 'application/json' },
        status: 400
      }
    );
  }
});`
    }
  ]);
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [editingFunction, setEditingFunction] = useState<string | null>(null);
  const [editedCode, setEditedCode] = useState<string>('');

  // Check authentication on mount
  useEffect(() => {
    if (!user) {
      navigate('/login', { state: { from: '/admin' } });
    }
  }, [user, navigate]);

  const handleDeploy = async (functionName: string) => {
    setLoading(prev => ({ ...prev, [functionName]: true }));
    setError(null);
    setSuccess(null);

    try {
      // Verify user is authenticated
      if (!user) {
        throw new Error('Please sign in to continue');
      }

      // Update status to deploying
      setFunctions(prev => prev.map(f => 
        f.name === functionName 
          ? { ...f, status: 'deploying' }
          : f
      ));

      // Get fresh session
      const { data: { session }, error: authError } = await supabase.auth.getSession();
      if (authError) throw authError;
      if (!session?.access_token) {
        throw new Error('Your session has expired. Please sign in again.');
      }

      // Get the Supabase URL from environment
      const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
      if (!supabaseUrl) {
        throw new Error('Supabase URL not configured');
      }

      // Call the deployment endpoint
      const response = await fetch(`${supabaseUrl}/functions/v1/deploy-function`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({ functionName })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Failed to deploy ${functionName}`);
      }

      const data = await response.json();

      // Update the function status
      setFunctions(prev => prev.map(f => 
        f.name === functionName 
          ? { ...f, status: 'deployed', lastUpdated: data.timestamp || new Date().toISOString() }
          : f
      ));

      setSuccess(`Successfully deployed ${functionName}`);
    } catch (err: any) {
      console.error('Deployment error:', err);
      
      // Handle authentication errors
      if (err.message.includes('invalid claim') || err.message.includes('expired')) {
        navigate('/login', { state: { from: '/admin' } });
        return;
      }
      
      setError(err.message || `Failed to deploy ${functionName}`);
      
      // Update the function status to failed
      setFunctions(prev => prev.map(f => 
        f.name === functionName 
          ? { ...f, status: 'failed' }
          : f
      ));
    } finally {
      setLoading(prev => ({ ...prev, [functionName]: false }));
    }
  };

  const handleEdit = (functionName: string) => {
    const func = functions.find(f => f.name === functionName);
    if (func) {
      setEditedCode(func.code || '');
      setEditingFunction(functionName);
    }
  };

  const handleSave = async () => {
    if (!editingFunction) return;

    setLoading(prev => ({ ...prev, [editingFunction]: true }));
    setError(null);
    setSuccess(null);

    try {
      // Verify user is authenticated
      if (!user) {
        throw new Error('Please sign in to continue');
      }

      // Update the function code in state
      setFunctions(prev => prev.map(f => 
        f.name === editingFunction
          ? { ...f, code: editedCode }
          : f
      ));

      // Automatically deploy after saving
      await handleDeploy(editingFunction);
      setSuccess(`Successfully saved and deployed ${editingFunction}`);
      setEditingFunction(null);
    } catch (err: any) {
      console.error('Save error:', err);
      
      // Handle authentication errors
      if (err.message.includes('invalid claim') || err.message.includes('expired')) {
        navigate('/login', { state: { from: '/admin' } });
        return;
      }
      
      setError(err.message || `Failed to save ${editingFunction}`);
    } finally {
      setLoading(prev => ({ ...prev, [editingFunction]: false }));
    }
  };

  // If not authenticated, don't render anything
  if (!user) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="flex items-center mb-6">
          <Cloud className="h-6 w-6 text-blue-600 mr-2" />
          <h2 className="text-2xl font-semibold">Edge Functions Management</h2>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md flex items-center">
            <CheckCircle className="h-5 w-5 mr-2" />
            {success}
          </div>
        )}

        <div className="space-y-4">
          {functions.map((func) => (
            <div
              key={func.name}
              className="border rounded-lg p-4"
            >
              {editingFunction === func.name ? (
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <h3 className="font-medium">{func.name}</h3>
                    <div className="flex gap-2">
                      <button
                        onClick={() => setEditingFunction(null)}
                        className="text-gray-600 hover:text-gray-800"
                      >
                        <X className="h-5 w-5" />
                      </button>
                      <button
                        onClick={handleSave}
                        disabled={loading[func.name]}
                        className={`flex items-center px-4 py-2 rounded-md ${
                          loading[func.name]
                            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                            : 'bg-blue-600 text-white hover:bg-blue-700'
                        }`}
                      >
                        {loading[func.name] ? (
                          <>
                            <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                            Saving...
                          </>
                        ) : (
                          <>
                            <Save className="h-4 w-4 mr-2" />
                            Save & Deploy
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                  <textarea
                    value={editedCode}
                    onChange={(e) => setEditedCode(e.target.value)}
                    className="w-full h-96 font-mono text-sm p-4 border rounded-md"
                    spellCheck={false}
                  />
                </div>
              ) : (
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="font-medium">{func.name}</h3>
                    <p className="text-sm text-gray-500">
                      Last updated: {new Date(func.lastUpdated).toLocaleString()}
                    </p>
                    <div className="flex items-center mt-1">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          func.status === 'deployed'
                            ? 'bg-green-100 text-green-800'
                            : func.status === 'failed'
                            ? 'bg-red-100 text-red-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {func.status === 'deployed' && (
                          <CheckCircle className="h-3 w-3 mr-1" />
                        )}
                        {func.status === 'failed' && (
                          <AlertCircle className="h-3 w-3 mr-1" />
                        )}
                        {func.status === 'deploying' && (
                          <RefreshCw className="h-3 w-3 mr-1 animate-spin" />
                        )}
                        {func.status.charAt(0).toUpperCase() + func.status.slice(1)}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleEdit(func.name)}
                      className="flex items-center px-4 py-2 text-blue-600 hover:text-blue-700"
                    >
                      <Code className="h-4 w-4 mr-2" />
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeploy(func.name)}
                      disabled={loading[func.name] || func.status === 'deploying'}
                      className={`flex items-center px-4 py-2 rounded-md ${
                        loading[func.name] || func.status === 'deploying'
                          ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                          : 'bg-blue-600 text-white hover:bg-blue-700'
                      }`}
                    >
                      {loading[func.name] || func.status === 'deploying' ? (
                        <>
                          <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                          Deploying...
                        </>
                      ) : (
                        <>
                          <ArrowUpCircle className="h-4 w-4 mr-2" />
                          Deploy
                        </>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}