import React, { useState } from 'react';
import { Upload, AlertCircle } from 'lucide-react';
import { useCategories } from '../hooks/useCategories';
import { useTopics } from '../hooks/useTopics';
import { useQuestions } from '../hooks/useQuestions';

export function QuestionUploader() {
  const { categories } = useCategories();
  const { topics } = useTopics();
  const { uploadQuestions } = useQuestions();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [bulkQuestions, setBulkQuestions] = useState('');
  const [uploadStatus, setUploadStatus] = useState<{ success: boolean; message: string } | null>(null);

  const handleUpload = async () => {
    if (!selectedTopicId) {
      setUploadStatus({
        success: false,
        message: 'Please select a topic for the questions.'
      });
      return;
    }

    try {
      await uploadQuestions(selectedTopicId, bulkQuestions);
      setUploadStatus({
        success: true,
        message: 'Questions uploaded successfully!'
      });
      setBulkQuestions('');
    } catch (error) {
      setUploadStatus({
        success: false,
        message: 'Error uploading questions. Please try again.'
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6">
          <div className="flex items-center mb-4">
            <Upload className="h-6 w-6 text-blue-600 mr-2" />
            <h2 className="text-2xl font-semibold">Bulk Upload Questions</h2>
          </div>
          
          <div className="space-y-6">
            {/* Topic Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Select Category and Topic</label>
              <div className="grid grid-cols-2 gap-4">
                <select
                  value={selectedCategoryId}
                  onChange={(e) => {
                    setSelectedCategoryId(e.target.value);
                    setSelectedTopicId('');
                  }}
                  className="px-3 py-2 border rounded-md"
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <select
                  value={selectedTopicId}
                  onChange={(e) => setSelectedTopicId(e.target.value)}
                  className="px-3 py-2 border rounded-md"
                  disabled={!selectedCategoryId}
                >
                  <option value="">Select Topic</option>
                  {topics
                    .filter(topic => topic.category_id === selectedCategoryId)
                    .map((topic) => (
                      <option key={topic.id} value={topic.id}>
                        {topic.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* Format Example */}
            <div className="bg-gray-50 p-6 rounded-md space-y-4">
              <h4 className="font-medium text-gray-700">Format Example:</h4>
              <pre className="text-sm text-gray-600 whitespace-pre-wrap bg-white p-4 rounded-md border border-gray-200">
{`1. What is Infrastructure as Code (IaC) and what are its key benefits in modern cloud environments?
A. A manual process for setting up servers
B. A practice where infrastructure is provisioned and managed using code and software development techniques
C. A type of programming language
D. A cloud service provider
Answer: B
Explanation: Infrastructure as Code (IaC) is a practice that enables the automation and management of infrastructure through code rather than manual processes. Key benefits include:
- Consistency and reproducibility in infrastructure deployment
- Version control and change tracking
- Reduced human error through automation
- Faster deployment and scaling
- Better collaboration between teams
- Cost efficiency through standardization

2. Which of the following are essential security measures for protecting cloud-based applications?
A. Network segmentation
B. Multi-factor authentication
C. Regular security audits
D. Encryption at rest and in transit
E. Access control policies
F. Security monitoring and logging
Answer: A,B,C,D,E,F
Explanation: All listed options are critical security measures for cloud applications:
- Network segmentation isolates resources and limits the blast radius of potential breaches
- MFA adds an extra layer of authentication security
- Regular audits help identify and address vulnerabilities
- Encryption protects sensitive data
- Access control ensures proper authorization
- Monitoring helps detect and respond to security incidents`}
              </pre>
            </div>

            {/* Upload Form */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Questions Input
                </label>
                <textarea
                  value={bulkQuestions}
                  onChange={(e) => setBulkQuestions(e.target.value)}
                  placeholder="Paste your questions here..."
                  className="w-full h-96 p-4 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 font-mono text-sm"
                  style={{ minHeight: '400px' }}
                />
              </div>

              {uploadStatus && (
                <div className={`p-4 rounded-md flex items-center ${
                  uploadStatus.success ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
                }`}>
                  {uploadStatus.success ? (
                    <div className="flex-1">{uploadStatus.message}</div>
                  ) : (
                    <>
                      <AlertCircle className="h-5 w-5 mr-2" />
                      <div className="flex-1">{uploadStatus.message}</div>
                    </>
                  )}
                </div>
              )}

              <button
                onClick={handleUpload}
                disabled={!selectedTopicId}
                className={`flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
                  !selectedTopicId ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                <Upload className="h-5 w-5 mr-2" />
                Upload Questions
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}