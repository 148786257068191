import { useState, useEffect } from 'react';
import { QuestionStatisticsService, TopicQuestionCount } from '../services/QuestionStatisticsService';

export function useQuestionStatistics() {
  const [questionCounts, setQuestionCounts] = useState<TopicQuestionCount[]>([]);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const statisticsService = QuestionStatisticsService.getInstance();

  const fetchStatistics = async () => {
    setLoading(true);
    setError(null);
    try {
      const [counts, total] = await Promise.all([
        statisticsService.getQuestionCountsByTopic(),
        statisticsService.getTotalQuestionCount()
      ]);
      setQuestionCounts(counts);
      setTotalQuestions(total);
    } catch (err: any) {
      console.error('Error fetching question statistics:', err);
      setError('Failed to load question statistics');
      setQuestionCounts([]);
      setTotalQuestions(0);
    } finally {
      setLoading(false);
    }
  };

  const getCountForTopic = (topicId: string): number => {
    const count = questionCounts.find(c => c.topic_id === topicId);
    return count ? count.count : 0;
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  return {
    questionCounts,
    totalQuestions,
    loading,
    error,
    getCountForTopic,
    refreshStatistics: fetchStatistics
  };
}