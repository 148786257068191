import React from 'react';
import { Filter } from 'lucide-react';
import { QuestionFilterBarProps } from '../../../types/question';

export function QuestionFilterBar({
  searchTerm,
  selectedCategoryId,
  selectedTopicId,
  onSearchChange,
  onCategoryChange,
  onTopicChange,
  onClearFilters,
  categories,
  topics
}: QuestionFilterBarProps) {
  const filteredTopics = topics.filter(topic => 
    !selectedCategoryId || topic.category_id === selectedCategoryId
  );

  return (
    <div className="space-y-4">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="flex-1">
          <input
            type="text"
            placeholder="Search questions..."
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            className="w-full pl-4 pr-10 py-2 border rounded-md"
          />
        </div>

        <div className="md:w-64">
          <select
            value={selectedCategoryId}
            onChange={(e) => onCategoryChange(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="md:w-64">
          <select
            value={selectedTopicId}
            onChange={(e) => onTopicChange(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            disabled={!selectedCategoryId}
          >
            <option value="">Select Topic</option>
            {filteredTopics.map((topic) => (
              <option key={topic.id} value={topic.id}>
                {topic.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {(selectedCategoryId || selectedTopicId || searchTerm) && (
        <div className="flex items-center text-sm text-gray-600">
          <Filter className="h-4 w-4 mr-2" />
          <span>
            Filtering by: {' '}
            {[
              searchTerm && 'Search term',
              selectedCategoryId && categories.find(c => c.id === selectedCategoryId)?.name,
              selectedTopicId && topics.find(t => t.id === selectedTopicId)?.name
            ].filter(Boolean).join(', ')}
          </span>
          <button
            onClick={onClearFilters}
            className="ml-2 text-blue-600 hover:text-blue-800"
          >
            Clear filters
          </button>
        </div>
      )}
    </div>
  );
}