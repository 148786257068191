import { useState } from 'react';
import { supabase } from '../../../lib/supabase';

interface Question {
  id: string;
  quiz_id: string;
  topic_id: string;
  question_text: string;
  explanation: string;
  answers: Answer[];
}

interface Answer {
  id: string;
  question_id: string;
  answer_text: string;
  is_correct: boolean;
}

export function useQuestionManager() {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchQuestions = async (topicId?: string) => {
    if (!topicId) {
      setQuestions([]);
      return;
    }

    setLoading(true);
    try {
      // Set session timeout before fetching
      await supabase.rpc('set_session_timeout');

      const { data, error } = await supabase
        .rpc('get_questions_for_topic', { p_topic_id: topicId });

      if (error) throw error;

      // Transform the data to match the Question interface
      const transformedQuestions = data.map((q: any) => ({
        ...q,
        answers: q.answers || []
      }));

      setQuestions(transformedQuestions || []);
    } catch (error) {
      console.error('Error fetching questions:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateQuestion = async (questionId: string, updates: Partial<Question>) => {
    try {
      const { error } = await supabase
        .from('questions')
        .update({
          question_text: updates.question_text,
          explanation: updates.explanation,
          topic_id: updates.topic_id
        })
        .eq('id', questionId);

      if (error) throw error;

      if (updates.answers) {
        for (const answer of updates.answers) {
          if (answer.id) {
            // Update existing answer
            const { error: answerError } = await supabase
              .from('answers')
              .update({
                answer_text: answer.answer_text,
                is_correct: answer.is_correct
              })
              .eq('id', answer.id);

            if (answerError) throw answerError;
          } else {
            // Add new answer
            const { error: answerError } = await supabase
              .from('answers')
              .insert([{
                question_id: questionId,
                answer_text: answer.answer_text,
                is_correct: answer.is_correct
              }]);

            if (answerError) throw answerError;
          }
        }
      }

      await fetchQuestions(updates.topic_id);
    } catch (error) {
      console.error('Error updating question:', error);
      throw error;
    }
  };

  const deleteQuestion = async (questionId: string, topicId: string) => {
    try {
      // First, delete all answers for this question
      const { error: answersError } = await supabase
        .from('answers')
        .delete()
        .eq('question_id', questionId);

      if (answersError) throw answersError;

      // Then, delete the question
      const { error: questionError } = await supabase
        .from('questions')
        .delete()
        .eq('id', questionId);

      if (questionError) throw questionError;

      await fetchQuestions(topicId);
    } catch (error) {
      console.error('Error deleting question:', error);
      throw error;
    }
  };

  const bulkDeleteQuestions = async (questionIds: string[], topicId: string) => {
    try {
      // Set session timeout before bulk operations
      await supabase.rpc('set_session_timeout');

      // Delete answers in batches of 100
      for (let i = 0; i < questionIds.length; i += 100) {
        const batch = questionIds.slice(i, i + 100);
        const { error: answersError } = await supabase
          .from('answers')
          .delete()
          .in('question_id', batch);

        if (answersError) {
          console.error('Error deleting answers:', answersError);
          throw new Error('Failed to delete answers: ' + answersError.message);
        }
      }

      // Delete questions in batches of 100
      for (let i = 0; i < questionIds.length; i += 100) {
        const batch = questionIds.slice(i, i + 100);
        const { error: questionsError } = await supabase
          .from('questions')
          .delete()
          .in('id', batch);

        if (questionsError) {
          console.error('Error deleting questions:', questionsError);
          throw new Error('Failed to delete questions: ' + questionsError.message);
        }
      }

      // Refresh the questions list
      await fetchQuestions(topicId);
    } catch (error: any) {
      console.error('Error bulk deleting questions:', error);
      throw new Error(error.message || 'Failed to delete questions');
    }
  };

  return {
    questions,
    loading,
    updateQuestion,
    deleteQuestion,
    bulkDeleteQuestions,
    fetchQuestions
  };
}