import React from 'react';
import { Plus, Save, X } from 'lucide-react';
import { QuestionEditorProps } from '../../../types/question';

export function QuestionForm({
  question,
  onSave,
  onCancel,
  topics
}: QuestionEditorProps) {
  const [editingQuestion, setEditingQuestion] = React.useState(question);
  const [error, setError] = React.useState<string | null>(null);

  const handleSave = async () => {
    try {
      await onSave(editingQuestion);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const addAnswer = () => {
    setEditingQuestion({
      ...editingQuestion,
      answers: [
        ...editingQuestion.answers,
        {
          question_id: editingQuestion.id,
          answer_text: '',
          is_correct: false
        }
      ]
    });
  };

  const removeAnswer = (index: number) => {
    const newAnswers = [...editingQuestion.answers];
    newAnswers.splice(index, 1);
    setEditingQuestion({
      ...editingQuestion,
      answers: newAnswers
    });
  };

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Question Text
        </label>
        <textarea
          value={editingQuestion.question_text}
          onChange={(e) => setEditingQuestion({
            ...editingQuestion,
            question_text: e.target.value
          })}
          className="w-full p-2 border rounded-md font-mono text-sm"
          rows={3}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Image URL (optional)
        </label>
        <div className="flex gap-2">
          <input
            type="text"
            value={editingQuestion.image_url || ''}
            onChange={(e) => setEditingQuestion({
              ...editingQuestion,
              image_url: e.target.value
            })}
            placeholder="Enter image URL"
            className="flex-1 p-2 border rounded-md"
          />
          <button
            onClick={() => setEditingQuestion({
              ...editingQuestion,
              image_url: ''
            })}
            className="px-2 py-1 text-gray-500 hover:text-gray-700"
            title="Clear image URL"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        {editingQuestion.image_url && (
          <div className="mt-2">
            <img
              src={editingQuestion.image_url}
              alt="Question"
              className="max-w-full h-auto rounded-md"
              onError={() => setError('Failed to load image. Please check the URL.')}
            />
          </div>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Topic
        </label>
        <select
          value={editingQuestion.topic_id}
          onChange={(e) => setEditingQuestion({
            ...editingQuestion,
            topic_id: e.target.value
          })}
          className="w-full p-2 border rounded-md"
        >
          {topics.map((topic) => (
            <option key={topic.id} value={topic.id}>
              {topic.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Explanation
        </label>
        <textarea
          value={editingQuestion.explanation}
          onChange={(e) => setEditingQuestion({
            ...editingQuestion,
            explanation: e.target.value
          })}
          className="w-full p-2 border rounded-md"
          rows={2}
        />
      </div>

      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Answers
          </label>
          <button
            onClick={addAnswer}
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <Plus className="h-4 w-4 mr-1" />
            Add Answer
          </button>
        </div>
        <div className="space-y-2">
          {editingQuestion.answers.map((answer, index) => (
            <div key={index} className="flex items-center gap-2">
              <input
                type="text"
                value={answer.answer_text}
                onChange={(e) => {
                  const newAnswers = [...editingQuestion.answers];
                  newAnswers[index].answer_text = e.target.value;
                  setEditingQuestion({
                    ...editingQuestion,
                    answers: newAnswers
                  });
                }}
                className="flex-1 p-2 border rounded-md"
                placeholder="Answer text"
              />
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={answer.is_correct}
                  onChange={(e) => {
                    const newAnswers = [...editingQuestion.answers];
                    newAnswers[index].is_correct = e.target.checked;
                    setEditingQuestion({
                      ...editingQuestion,
                      answers: newAnswers
                    });
                  }}
                  className="mr-2"
                />
                Correct
              </label>
              <button
                onClick={() => removeAnswer(index)}
                className="text-red-600 hover:text-red-800"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-md">
          {error}
        </div>
      )}

      <div className="flex justify-end gap-2">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-gray-600 hover:text-gray-800"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          <Save className="h-4 w-4 mr-2" />
          Save Changes
        </button>
      </div>
    </div>
  );
}