import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreditCard, CheckCircle, AlertCircle, Loader2, X } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { SubscriptionService } from '../services/SubscriptionService';
import { useAuthStore } from '../store/auth';
import { useSubscriptionStore } from '../store/subscription';
import type { SubscriptionTier, UserSubscription } from '../types/subscription';
import { PaymentForm } from '../components/subscription/PaymentForm';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export function Subscription() {
  const [tiers, setTiers] = useState<SubscriptionTier[]>([]);
  const [currentSubscription, setCurrentSubscription] = useState<UserSubscription | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [selectedTier, setSelectedTier] = useState<SubscriptionTier | null>(null);
  const { user } = useAuthStore();
  const { checkSubscription } = useSubscriptionStore();
  const navigate = useNavigate();
  const subscriptionService = SubscriptionService.getInstance();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    
    loadData();
  }, [user, navigate]);

  const loadData = async () => {
    try {
      setLoading(true);
      const [tiersData, subscription] = await Promise.all([
        subscriptionService.getSubscriptionTiers(),
        subscriptionService.getCurrentSubscription()
      ]);
      setTiers(tiersData);
      setCurrentSubscription(subscription);
    } catch (err: any) {
      console.error('Error loading subscription data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async (tier: SubscriptionTier) => {
    setSelectedTier(tier);
  };

  const handleCancel = async () => {
    if (!confirm('Are you sure you want to cancel your subscription? You will still have access until the end of your current billing period.')) {
      return;
    }

    try {
      setProcessing(true);
      setError(null);
      await subscriptionService.cancelSubscription();
      await Promise.all([
        loadData(),
        checkSubscription()
      ]);
    } catch (err: any) {
      console.error('Error canceling subscription:', err);
      setError(err.message);
    } finally {
      setProcessing(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-center items-center">
            <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
            <span className="ml-3">Loading subscription options...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6">
          <div className="flex items-center mb-6">
            <CreditCard className="h-6 w-6 text-blue-600 mr-2" />
            <h2 className="text-2xl font-semibold">Subscription Plans</h2>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
              <AlertCircle className="h-5 w-5 mr-2" />
              {error}
              <button
                onClick={() => setError(null)}
                className="ml-auto text-red-700 hover:text-red-800"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          )}

          {currentSubscription && (
            <div className="mb-6 p-4 bg-blue-50 rounded-lg">
              <h3 className="text-lg font-medium text-blue-900">Current Subscription</h3>
              <div className="mt-2 space-y-2">
                <p className="text-blue-800">
                  Status: <span className="font-medium capitalize">{currentSubscription.status}</span>
                </p>
                <p className="text-blue-800">
                  Current Period: <span className="font-medium">
                    {new Date(currentSubscription.current_period_start).toLocaleDateString()} - {new Date(currentSubscription.current_period_end).toLocaleDateString()}
                  </span>
                </p>
                {currentSubscription.cancel_at_period_end ? (
                  <div className="bg-orange-100 text-orange-800 p-3 rounded-md mt-3">
                    <p className="font-medium">Subscription Cancellation Scheduled</p>
                    <p className="text-sm mt-1">
                      Your subscription will remain active until {new Date(currentSubscription.current_period_end).toLocaleDateString()}.
                      You can continue using all features until then.
                    </p>
                  </div>
                ) : (
                  <button
                    onClick={handleCancel}
                    disabled={processing}
                    className={`mt-2 px-4 py-2 text-red-600 hover:text-red-800 ${
                      processing ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {processing ? 'Processing...' : 'Cancel Subscription'}
                  </button>
                )}
              </div>
            </div>
          )}

          <div className="grid md:grid-cols-2 gap-6">
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className={`border rounded-lg p-6 ${
                  currentSubscription?.tier_id === tier.id
                    ? 'border-blue-500 bg-blue-50'
                    : 'hover:border-gray-300'
                }`}
              >
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-xl font-semibold">{tier.name}</h3>
                    <p className="text-gray-600">{tier.description}</p>
                  </div>
                  <div className="text-right">
                    <div className="text-2xl font-bold">
                      ${(tier.price_amount / 100).toFixed(2)}
                    </div>
                    <div className="text-sm text-gray-500">
                      per {tier.interval}
                    </div>
                  </div>
                </div>

                <ul className="space-y-3 mb-6">
                  {tier.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-700">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <button
                  onClick={() => handleSubscribe(tier)}
                  disabled={processing || currentSubscription?.tier_id === tier.id}
                  className={`w-full px-6 py-3 text-center rounded-lg ${
                    currentSubscription?.tier_id === tier.id
                      ? 'bg-green-600 text-white cursor-not-allowed'
                      : 'bg-blue-600 text-white hover:bg-blue-700'
                  } disabled:opacity-50 disabled:cursor-not-allowed transition-colors`}
                >
                  {processing ? (
                    <Loader2 className="h-5 w-5 animate-spin mx-auto" />
                  ) : currentSubscription?.tier_id === tier.id ? (
                    'Current Plan'
                  ) : (
                    'Subscribe Now'
                  )}
                </button>
              </div>
            ))}
          </div>

          {selectedTier && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
              <div className="bg-white rounded-lg p-6 max-w-md w-full">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold">Complete Subscription</h3>
                  <button
                    onClick={() => setSelectedTier(null)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>

                <Elements stripe={stripePromise}>
                  <PaymentForm
                    tier={selectedTier}
                    onSuccess={() => {
                      setSelectedTier(null);
                      loadData();
                      checkSubscription();
                    }}
                    onError={(err) => setError(err)}
                  />
                </Elements>
              </div>
            </div>
          )}

          <div className="mt-8 text-center text-sm text-gray-500">
            <p>
              Subscriptions are processed securely through Stripe. 
              You can cancel at any time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}