import { useState, useEffect } from 'react';
import { QuizState, QuizSettings, Question } from '../types/quiz';

export function useQuizState(initialSettings: QuizSettings) {
  const [state, setState] = useState<QuizState>({
    questions: [],
    currentQuestionIndex: 0,
    selectedAnswers: {},
    timeRemaining: initialSettings.timeLimit * 60,
    quizComplete: false,
    score: 0,
    flaggedQuestions: new Set()
  });

  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!state.quizComplete && state.timeRemaining > 0 && !isPaused) {
      timer = setInterval(() => {
        setState(prev => {
          if (prev.timeRemaining <= 1) {
            // Calculate score when time runs out
            const score = calculateScoreInternal(prev);
            return { 
              ...prev, 
              timeRemaining: 0, 
              quizComplete: true,
              score 
            };
          }
          return { ...prev, timeRemaining: prev.timeRemaining - 1 };
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [state.timeRemaining, state.quizComplete, isPaused]);

  const calculateScoreInternal = (currentState: QuizState) => {
    let correctCount = 0;
    currentState.questions.forEach(question => {
      const selectedAnswerIds = currentState.selectedAnswers[question.id] || [];
      const correctAnswerIds = question.answers
        .filter(answer => answer.is_correct)
        .map(answer => answer.id);

      const isCorrect = 
        selectedAnswerIds.length === correctAnswerIds.length &&
        selectedAnswerIds.every(id => correctAnswerIds.includes(id));

      if (isCorrect) correctCount++;
    });

    return Math.round((correctCount / currentState.questions.length) * 100);
  };

  const setQuestions = (questions: Question[]) => {
    setState(prev => ({
      ...prev,
      questions,
      timeRemaining: initialSettings.timeLimit * 60,
      currentQuestionIndex: 0,
      selectedAnswers: {},
      flaggedQuestions: new Set(),
      quizComplete: false,
      score: 0
    }));
  };

  const selectAnswer = (questionId: string, answerId: string) => {
    setState(prev => {
      const currentAnswers = prev.selectedAnswers[questionId] || [];
      const isSelected = currentAnswers.includes(answerId);
      
      // Get the current question
      const question = prev.questions.find(q => q.id === questionId);
      if (!question) return prev;

      // Count correct answers for this question
      const correctAnswersCount = question.answers.filter(a => a.is_correct).length;
      
      let newAnswers: string[];
      if (correctAnswersCount === 1) {
        // For single-answer questions, replace the selection
        newAnswers = [answerId];
      } else {
        // For multiple-answer questions, toggle the selection
        if (isSelected) {
          // Remove the answer if it's already selected
          newAnswers = currentAnswers.filter(id => id !== answerId);
        } else {
          // Add the answer if we haven't reached the limit
          if (currentAnswers.length < correctAnswersCount) {
            newAnswers = [...currentAnswers, answerId];
          } else {
            // If we've reached the limit, don't add the new answer
            return prev;
          }
        }
      }

      return {
        ...prev,
        selectedAnswers: {
          ...prev.selectedAnswers,
          [questionId]: newAnswers
        }
      };
    });
  };

  const toggleFlaggedQuestion = (index: number) => {
    setState(prev => {
      const newFlagged = new Set(prev.flaggedQuestions);
      if (newFlagged.has(index)) {
        newFlagged.delete(index);
      } else {
        newFlagged.add(index);
      }
      return { ...prev, flaggedQuestions: newFlagged };
    });
  };

  const calculateScore = () => {
    const score = calculateScoreInternal(state);
    setState(prev => ({ ...prev, score, quizComplete: true }));
    return score;
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  return {
    state,
    setQuestions,
    selectAnswer,
    toggleFlaggedQuestion,
    calculateScore,
    setState,
    isPaused,
    togglePause
  };
}