import { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabase';

interface Stats {
  totalUsers: number;
  totalQuizzes: number;
  totalFlashcards: number;
  totalCategories: number;
  totalTopics: number;
  totalQuestions: number;
}

export function useStats() {
  const [stats, setStats] = useState<Stats>({
    totalUsers: 0,
    totalQuizzes: 0,
    totalFlashcards: 0,
    totalCategories: 0,
    totalTopics: 0,
    totalQuestions: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchStats = async () => {
    setIsLoading(true);
    try {
      const [
        { count: userCount },
        { count: quizCount },
        { count: flashcardCount },
        { count: categoryCount },
        { count: topicCount },
        { count: questionCount }
      ] = await Promise.all([
        supabase.from('profiles').select('*', { count: 'exact', head: true }),
        supabase.from('quizzes').select('*', { count: 'exact', head: true }),
        supabase.from('flashcards').select('*', { count: 'exact', head: true }),
        supabase.from('categories').select('*', { count: 'exact', head: true }),
        supabase.from('topics').select('*', { count: 'exact', head: true }),
        supabase.from('questions').select('*', { count: 'exact', head: true })
      ]);

      setStats({
        totalUsers: userCount || 0,
        totalQuizzes: quizCount || 0,
        totalFlashcards: flashcardCount || 0,
        totalCategories: categoryCount || 0,
        totalTopics: topicCount || 0,
        totalQuestions: questionCount || 0
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return { stats, isLoading, refreshStats: fetchStats };
}