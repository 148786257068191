import { supabase } from '../lib/supabase';

export interface TopicQuestionCount {
  topic_id: string;
  count: number;
}

export class QuestionStatisticsService {
  private static instance: QuestionStatisticsService;

  private constructor() {}

  public static getInstance(): QuestionStatisticsService {
    if (!QuestionStatisticsService.instance) {
      QuestionStatisticsService.instance = new QuestionStatisticsService();
    }
    return QuestionStatisticsService.instance;
  }

  async getQuestionCountsByTopic(): Promise<TopicQuestionCount[]> {
    try {
      const { data, error } = await supabase.rpc('get_topic_question_counts');

      if (error) {
        console.error('Database error:', error);
        throw new Error('Failed to fetch question counts');
      }

      return data || [];
    } catch (error) {
      console.error('Error fetching question counts:', error);
      return []; // Return empty array instead of throwing
    }
  }

  async getQuestionCountForTopic(topicId: string): Promise<number> {
    try {
      const { count, error } = await supabase
        .from('questions')
        .select('*', { count: 'exact', head: true })
        .eq('topic_id', topicId);

      if (error) {
        console.error('Database error:', error);
        return 0;
      }

      return count || 0;
    } catch (error) {
      console.error('Error fetching question count for topic:', error);
      return 0; // Return 0 instead of throwing
    }
  }

  async getTotalQuestionCount(): Promise<number> {
    try {
      const { count, error } = await supabase
        .from('questions')
        .select('*', { count: 'exact', head: true });

      if (error) {
        console.error('Database error:', error);
        return 0;
      }

      return count || 0;
    } catch (error) {
      console.error('Error fetching total question count:', error);
      return 0; // Return 0 instead of throwing
    }
  }
}