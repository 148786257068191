import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Validate Supabase URL
if (!supabaseUrl || !supabaseUrl.startsWith('https://')) {
  throw new Error('Invalid Supabase URL. Please ensure VITE_SUPABASE_URL is set correctly and starts with https://');
}

if (!supabaseAnonKey) {
  throw new Error('Missing environment variables. Please connect to Supabase by clicking the "Connect to Supabase" button in the top right corner.');
}

// Get site URL for auth redirects
const getSiteUrl = () => {
  // In production, use the deployed domain
  if (import.meta.env.PROD) {
    return 'https://examengines.com';
  }
  // In development, use the Vite dev server URL
  return 'http://localhost:5173';
};

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storage: window.localStorage,
    storageKey: 'supabase.auth.token',
    flowType: 'pkce',
    // Set the site URL for auth redirects
    site_url: getSiteUrl()
  }
});

// Helper function to check if Supabase is connected
export const checkSupabaseConnection = async () => {
  try {
    // First check if we have a session
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    
    if (sessionError) {
      console.error('Session error:', sessionError.message);
      return false;
    }

    if (!session) {
      console.error('No active session found');
      return false;
    }
    
    // Then check if we can access the database
    const { count, error } = await supabase
      .from('profiles')
      .select('*', { count: 'exact', head: true });

    if (error) {
      console.error('Database error:', error.message);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Failed to check Supabase connection:', error);
    return false;
  }
};

// Helper function to ensure authenticated state
export const ensureAuthenticated = async () => {
  const { data: { session }, error } = await supabase.auth.getSession();
  
  if (error) {
    console.error('Auth error:', error.message);
    throw new Error('Authentication error');
  }
  
  if (!session) {
    throw new Error('Not authenticated');
  }
  
  // Check if the session needs refresh
  if (session.expires_at && Date.now() / 1000 > session.expires_at) {
    const { data: { session: newSession }, error: refreshError } = await supabase.auth.refreshSession();
    
    if (refreshError) {
      console.error('Session refresh error:', refreshError.message);
      throw new Error('Session expired');
    }
    
    if (!newSession) {
      throw new Error('Failed to refresh session');
    }
    
    return newSession;
  }
  
  return session;
};