import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Loader2 } from 'lucide-react';
import { SubscriptionService } from '../../services/SubscriptionService';
import type { SubscriptionTier } from '../../types/subscription';

interface PaymentFormProps {
  tier: SubscriptionTier;
  onSuccess: () => void;
  onError: (error: string) => void;
}

export function PaymentForm({ tier, onSuccess, onError }: PaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const subscriptionService = SubscriptionService.getInstance();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      onError('Payment system not initialized');
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      onError('Payment form not found');
      return;
    }

    setProcessing(true);

    try {
      // Create checkout session
      const sessionId = await subscriptionService.createCheckoutSession(tier.price_id);

      // Redirect to Stripe checkout
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        throw error;
      }

      onSuccess();
    } catch (err: any) {
      console.error('Payment error:', err);
      onError(err.message || 'Failed to process payment');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Card Details
        </label>
        <div className="p-3 border rounded-md bg-white">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
      </div>

      <div className="bg-gray-50 p-4 rounded-md">
        <div className="flex justify-between mb-2">
          <span className="text-gray-600">Plan</span>
          <span className="font-medium">{tier.name}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Total</span>
          <span className="font-medium">
            ${(tier.price_amount / 100).toFixed(2)} / {tier.interval}
          </span>
        </div>
      </div>

      <button
        type="submit"
        disabled={!stripe || processing}
        className={`w-full flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed`}
      >
        {processing ? (
          <>
            <Loader2 className="h-5 w-5 animate-spin mr-2" />
            Processing...
          </>
        ) : (
          'Subscribe Now'
        )}
      </button>

      <p className="text-sm text-gray-500 text-center">
        Your card will be charged ${(tier.price_amount / 100).toFixed(2)} {tier.interval}ly
      </p>
    </form>
  );
}