import { useState } from 'react';
import { FlashcardState } from '../types/flashcard';

export function useFlashcardState(totalCards: number) {
  const [state, setState] = useState<FlashcardState>({
    currentIndex: 0,
    isFlipped: false
  });

  const handleNext = () => {
    if (state.currentIndex < totalCards - 1) {
      setState({ currentIndex: state.currentIndex + 1, isFlipped: false });
    }
  };

  const handlePrevious = () => {
    if (state.currentIndex > 0) {
      setState({ currentIndex: state.currentIndex - 1, isFlipped: false });
    }
  };

  const handleFlip = () => {
    setState(prev => ({ ...prev, isFlipped: !prev.isFlipped }));
  };

  return {
    state,
    handleNext,
    handlePrevious,
    handleFlip
  };
}