import React, { useState, useEffect } from 'react';
import { Edit2, Trash2, X, Filter, AlertCircle, AlertTriangle } from 'lucide-react';
import { useFlashcardManager } from '../hooks/useFlashcardManager';
import { useTopics } from '../hooks/useTopics';
import { useCategories } from '../hooks/useCategories';
import { Flashcard } from '../../../types/flashcard';

export function FlashcardEditor() {
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [editingFlashcard, setEditingFlashcard] = useState<Flashcard | null>(null);
  const [selectedFlashcards, setSelectedFlashcards] = useState<string[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  const { flashcards, loading, updateFlashcard, deleteFlashcard, bulkDeleteFlashcards, fetchFlashcards } = useFlashcardManager();
  const { topics } = useTopics();
  const { categories } = useCategories();

  // Fetch flashcards when filters change
  useEffect(() => {
    if (selectedTopicId) {
      fetchFlashcards(selectedTopicId, selectedCategoryId);
    } else {
      // Clear flashcards when no topic is selected
      fetchFlashcards();
    }
  }, [selectedTopicId, selectedCategoryId]);

  const handleSave = async () => {
    if (!editingFlashcard) return;

    try {
      await updateFlashcard(editingFlashcard.id, editingFlashcard);
      setEditingFlashcard(null);
      setError(null);
    } catch (error: any) {
      setError(error.message || 'Failed to save flashcard');
    }
  };

  const handleDelete = async (flashcardId: string) => {
    if (!selectedTopicId) return;

    if (confirm('Are you sure you want to delete this flashcard?')) {
      try {
        await deleteFlashcard(flashcardId, selectedTopicId, selectedCategoryId);
        setError(null);
      } catch (error: any) {
        setError(error.message || 'Failed to delete flashcard');
      }
    }
  };

  const handleBulkDelete = async () => {
    if (!selectedTopicId) return;

    if (selectedFlashcards.length === 0) {
      setError('Please select flashcards to delete');
      return;
    }

    if (confirm(`Are you sure you want to delete ${selectedFlashcards.length} flashcards?`)) {
      try {
        await bulkDeleteFlashcards(selectedFlashcards, selectedTopicId, selectedCategoryId);
        setSelectedFlashcards([]);
        setError(null);
      } catch (error: any) {
        setError(error.message || 'Failed to delete flashcards');
      }
    }
  };

  const handleBulkDeleteTopic = async () => {
    if (!selectedTopicId) {
      setError('Please select a topic first');
      return;
    }

    try {
      const topicFlashcards = flashcards.filter(f => f.topic_id === selectedTopicId);
      if (topicFlashcards.length === 0) {
        setError('No flashcards found in this topic');
        return;
      }

      await bulkDeleteFlashcards(
        topicFlashcards.map(f => f.id),
        selectedTopicId,
        selectedCategoryId
      );
      setShowDeleteConfirm(false);
      setError(null);
    } catch (error: any) {
      setError(error.message || 'Failed to delete flashcards');
    }
  };

  const toggleFlashcardSelection = (flashcardId: string) => {
    setSelectedFlashcards(prev => 
      prev.includes(flashcardId)
        ? prev.filter(id => id !== flashcardId)
        : [...prev, flashcardId]
    );
  };

  const toggleSelectAll = () => {
    if (selectedFlashcards.length === filteredFlashcards.length) {
      setSelectedFlashcards([]);
    } else {
      setSelectedFlashcards(filteredFlashcards.map(f => f.id));
    }
  };

  const filteredFlashcards = flashcards.filter(f => {
    const matchesSearch = 
      f.front_content.toLowerCase().includes(searchTerm.toLowerCase()) ||
      f.back_content.toLowerCase().includes(searchTerm.toLowerCase());
    
    return matchesSearch;
  });

  const filteredTopics = topics.filter(topic => 
    !selectedCategoryId || topic.category_id === selectedCategoryId
  );

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="flex flex-col space-y-4 mb-6">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold">Flashcard Manager</h2>
            <div className="flex gap-2">
              {selectedFlashcards.length > 0 && (
                <button
                  onClick={handleBulkDelete}
                  className="flex items-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  <Trash2 className="h-4 w-4 mr-2" />
                  Delete Selected ({selectedFlashcards.length})
                </button>
              )}
              {selectedTopicId && (
                <button
                  onClick={() => setShowDeleteConfirm(true)}
                  className="flex items-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  <Trash2 className="h-4 w-4 mr-2" />
                  Delete All in Topic
                </button>
              )}
            </div>
          </div>
          
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <input
                type="text"
                placeholder="Search flashcards..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-4 pr-10 py-2 border rounded-md"
              />
            </div>

            <div className="md:w-64">
              <select
                value={selectedCategoryId}
                onChange={(e) => {
                  setSelectedCategoryId(e.target.value);
                  setSelectedTopicId('');
                }}
                className="w-full px-3 py-2 border rounded-md"
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="md:w-64">
              <select
                value={selectedTopicId}
                onChange={(e) => setSelectedTopicId(e.target.value)}
                className="w-full px-3 py-2 border rounded-md"
                disabled={!selectedCategoryId}
              >
                <option value="">Select Topic</option>
                {filteredTopics.map((topic) => (
                  <option key={topic.id} value={topic.id}>
                    {topic.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {(selectedCategoryId || selectedTopicId || searchTerm) && (
            <div className="flex items-center text-sm text-gray-600">
              <Filter className="h-4 w-4 mr-2" />
              <span>
                Filtering by: {' '}
                {[
                  searchTerm && 'Search term',
                  selectedCategoryId && categories.find(c => c.id === selectedCategoryId)?.name,
                  selectedTopicId && topics.find(t => t.id === selectedTopicId)?.name
                ].filter(Boolean).join(', ')}
              </span>
              <button
                onClick={() => {
                  setSearchTerm('');
                  setSelectedCategoryId('');
                  setSelectedTopicId('');
                }}
                className="ml-2 text-blue-600 hover:text-blue-800"
              >
                Clear filters
              </button>
            </div>
          )}

          {error && (
            <div className="bg-red-50 text-red-700 p-4 rounded-md flex items-center">
              <AlertCircle className="h-5 w-5 mr-2" />
              {error}
            </div>
          )}
        </div>

        {!selectedTopicId ? (
          <div className="text-center py-8 text-gray-500">
            Please select a category and topic to view and manage flashcards
          </div>
        ) : loading ? (
          <div className="text-center py-4">
            <div className="inline-flex items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
              <span className="ml-3">Loading flashcards...</span>
            </div>
          </div>
        ) : filteredFlashcards.length === 0 ? (
          <div className="text-center py-4 text-gray-500">
            No flashcards found matching the current filters
          </div>
        ) : (
          <div className="space-y-4">
            {/* Select All Checkbox */}
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={selectedFlashcards.length === filteredFlashcards.length}
                onChange={toggleSelectAll}
                className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
              <label className="ml-2 text-sm text-gray-700">
                Select All Flashcards
              </label>
            </div>

            {filteredFlashcards.map((flashcard) => (
              <div key={flashcard.id} className="border rounded-lg p-4">
                {editingFlashcard?.id === flashcard.id ? (
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Front Content
                      </label>
                      <textarea
                        value={editingFlashcard.front_content}
                        onChange={(e) => setEditingFlashcard({
                          ...editingFlashcard,
                          front_content: e.target.value
                        })}
                        className="w-full p-2 border rounded-md"
                        rows={3}
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Back Content
                      </label>
                      <textarea
                        value={editingFlashcard.back_content}
                        onChange={(e) => setEditingFlashcard({
                          ...editingFlashcard,
                          back_content: e.target.value
                        })}
                        className="w-full p-2 border rounded-md"
                        rows={3}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Category
                        </label>
                        <select
                          value={editingFlashcard.category_id || ''}
                          onChange={(e) => {
                            setEditingFlashcard({
                              ...editingFlashcard,
                              category_id: e.target.value,
                              topic_id: '' // Reset topic when category changes
                            });
                          }}
                          className="w-full p-2 border rounded-md"
                        >
                          <option value="">Select Category</option>
                          {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Topic
                        </label>
                        <select
                          value={editingFlashcard.topic_id || ''}
                          onChange={(e) => setEditingFlashcard({
                            ...editingFlashcard,
                            topic_id: e.target.value
                          })}
                          className="w-full p-2 border rounded-md"
                        >
                          <option value="">Select Topic</option>
                          {topics
                            .filter(topic => topic.category_id === editingFlashcard.category_id)
                            .map((topic) => (
                              <option key={topic.id} value={topic.id}>
                                {topic.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="flex justify-end gap-2">
                      <button
                        onClick={() => setEditingFlashcard(null)}
                        className="px-4 py-2 text-gray-600 hover:text-gray-800"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex justify-between items-start mb-4">
                      <div className="flex-1">
                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            checked={selectedFlashcards.includes(flashcard.id)}
                            onChange={() => toggleFlashcardSelection(flashcard.id)}
                            className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                          />
                          <div className="text-sm text-gray-500">
                            Category: {categories.find(c => c.id === flashcard.category_id)?.name} • 
                            Topic: {topics.find(t => t.id === flashcard.topic_id)?.name}
                          </div>
                        </div>
                        <div className="bg-gray-50 p-4 rounded-md mt-2 mb-2">
                          <h3 className="font-medium mb-1">Front</h3>
                          <p className="text-gray-700">{flashcard.front_content}</p>
                        </div>
                        <div className="bg-gray-50 p-4 rounded-md">
                          <h3 className="font-medium mb-1">Back</h3>
                          <p className="text-gray-700">{flashcard.back_content}</p>
                        </div>
                      </div>
                      <div className="flex gap-2 ml-4">
                        <button
                          onClick={() => setEditingFlashcard(flashcard)}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Edit2 className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleDelete(flashcard.id)}
                          className="text-red-600 hover:text-red-800"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Delete All Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex items-center mb-4 text-red-600">
              <AlertTriangle className="h-6 w-6 mr-2" />
              <h3 className="text-lg font-semibold">Confirm Bulk Delete</h3>
            </div>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete ALL flashcards in the topic "
              {topics.find(t => t.id === selectedTopicId)?.name}"? 
              This action cannot be undone.
            </p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleBulkDeleteTopic}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Delete All
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}