import React, { useState } from 'react';
import { Plus, Save, Trash2, AlertCircle, Search, Filter } from 'lucide-react';
import { useQuizBuilder } from '../hooks/useQuizBuilder';
import { useTopics } from '../hooks/useTopics';
import { useCategories } from '../hooks/useCategories';

interface Quiz {
  id?: string;
  title: string;
  description: string;
  time_limit: number;
  passing_score: number;
  questions: string[]; // Array of question IDs
}

export function QuizBuilder() {
  const { topics } = useTopics();
  const { categories } = useCategories();
  const { saving, loading, questions, saveQuiz } = useQuizBuilder();
  const [quiz, setQuiz] = useState<Quiz>({
    title: '',
    description: '',
    time_limit: 60,
    passing_score: 70,
    questions: []
  });
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const filteredQuestions = questions.filter(q => {
    const matchesSearch = q.question_text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.explanation?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesTopic = !selectedTopicId || q.topic_id === selectedTopicId;
    
    return matchesSearch && matchesTopic;
  });

  const handleSubmit = async () => {
    try {
      setError(null);
      setSuccess(null);

      // Validation
      if (!quiz.title.trim()) {
        setError('Quiz title is required');
        return;
      }

      if (quiz.questions.length === 0) {
        setError('At least one question is required');
        return;
      }

      await saveQuiz(quiz);
      setSuccess('Quiz saved successfully!');

      // Reset form
      setQuiz({
        title: '',
        description: '',
        time_limit: 60,
        passing_score: 70,
        questions: []
      });
      setSelectedCategoryId('');
      setSelectedTopicId('');
      setSearchTerm('');
    } catch (error) {
      console.error('Error saving quiz:', error);
      setError('Failed to save quiz. Please try again.');
    }
  };

  const toggleQuestion = (questionId: string) => {
    setQuiz(prev => ({
      ...prev,
      questions: prev.questions.includes(questionId)
        ? prev.questions.filter(id => id !== questionId)
        : [...prev.questions, questionId]
    }));
  };

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <h2 className="text-2xl font-semibold mb-6">Create New Quiz</h2>

        {/* Quiz Details */}
        <div className="space-y-4 mb-8">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Quiz Title
            </label>
            <input
              type="text"
              value={quiz.title}
              onChange={(e) => setQuiz({ ...quiz, title: e.target.value })}
              className="w-full px-3 py-2 border rounded-md"
              placeholder="Enter quiz title"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              value={quiz.description}
              onChange={(e) => setQuiz({ ...quiz, description: e.target.value })}
              className="w-full px-3 py-2 border rounded-md"
              rows={3}
              placeholder="Enter quiz description"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Time Limit (minutes)
              </label>
              <input
                type="number"
                value={quiz.time_limit}
                onChange={(e) => setQuiz({ ...quiz, time_limit: parseInt(e.target.value) })}
                className="w-full px-3 py-2 border rounded-md"
                min="1"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Passing Score (%)
              </label>
              <input
                type="number"
                value={quiz.passing_score}
                onChange={(e) => setQuiz({ ...quiz, passing_score: parseInt(e.target.value) })}
                className="w-full px-3 py-2 border rounded-md"
                min="0"
                max="100"
              />
            </div>
          </div>
        </div>

        {/* Question Selection */}
        <div className="space-y-6">
          <div className="flex flex-col space-y-4">
            <h3 className="text-lg font-medium">Select Questions</h3>
            
            <div className="flex flex-col md:flex-row gap-4">
              {/* Search Input */}
              <div className="flex-1">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search questions..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border rounded-md"
                  />
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
              </div>

              {/* Category Filter */}
              <div className="md:w-64">
                <select
                  value={selectedCategoryId}
                  onChange={(e) => {
                    setSelectedCategoryId(e.target.value);
                    setSelectedTopicId('');
                  }}
                  className="w-full px-3 py-2 border rounded-md"
                >
                  <option value="">All Categories</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Topic Filter */}
              <div className="md:w-64">
                <select
                  value={selectedTopicId}
                  onChange={(e) => setSelectedTopicId(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md"
                  disabled={!selectedCategoryId}
                >
                  <option value="">All Topics</option>
                  {topics
                    .filter(topic => topic.category_id === selectedCategoryId)
                    .map((topic) => (
                      <option key={topic.id} value={topic.id}>
                        {topic.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* Filter Summary */}
            {(selectedCategoryId || selectedTopicId || searchTerm) && (
              <div className="flex items-center text-sm text-gray-600">
                <Filter className="h-4 w-4 mr-2" />
                <span>
                  Filtering by: {' '}
                  {[
                    searchTerm && 'Search term',
                    selectedCategoryId && categories.find(c => c.id === selectedCategoryId)?.name,
                    selectedTopicId && topics.find(t => t.id === selectedTopicId)?.name
                  ].filter(Boolean).join(', ')}
                </span>
                <button
                  onClick={() => {
                    setSearchTerm('');
                    setSelectedCategoryId('');
                    setSelectedTopicId('');
                  }}
                  className="ml-2 text-blue-600 hover:text-blue-800"
                >
                  Clear filters
                </button>
              </div>
            )}
          </div>

          {loading ? (
            <div className="text-center py-4">Loading questions...</div>
          ) : filteredQuestions.length === 0 ? (
            <div className="text-center py-4 text-gray-500">
              No questions found matching the current filters
            </div>
          ) : (
            <div className="space-y-4">
              {filteredQuestions.map((question) => (
                <div
                  key={question.id}
                  className={`border rounded-lg p-4 cursor-pointer transition-colors ${
                    quiz.questions.includes(question.id)
                      ? 'border-blue-500 bg-blue-50'
                      : 'hover:border-gray-400'
                  }`}
                  onClick={() => toggleQuestion(question.id)}
                >
                  <div className="flex items-start gap-4">
                    <input
                      type="checkbox"
                      checked={quiz.questions.includes(question.id)}
                      onChange={() => toggleQuestion(question.id)}
                      className="mt-1"
                    />
                    <div className="flex-1">
                      <div className="text-sm text-gray-500 mb-1">
                        Topic: {topics.find(t => t.id === question.topic_id)?.name}
                      </div>
                      <p className="font-medium mb-2">{question.question_text}</p>
                      <div className="space-y-1">
                        {question.answers.map((answer, index) => (
                          <div
                            key={answer.id}
                            className={`text-sm ${
                              answer.is_correct ? 'text-green-600' : 'text-gray-600'
                            }`}
                          >
                            {String.fromCharCode(65 + index)}. {answer.answer_text}
                            {answer.is_correct && ' ✓'}
                          </div>
                        ))}
                      </div>
                      {question.explanation && (
                        <p className="text-sm text-gray-600 mt-2">
                          <span className="font-medium">Explanation:</span> {question.explanation}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Error/Success Messages */}
        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mt-4 p-4 bg-green-50 text-green-700 rounded-md">
            {success}
          </div>
        )}

        {/* Submit Button */}
        <div className="mt-8 flex justify-end">
          <button
            onClick={handleSubmit}
            disabled={saving}
            className={`flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
              saving ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <Save className="h-5 w-5 mr-2" />
            {saving ? 'Saving...' : 'Save Quiz'}
          </button>
        </div>
      </div>
    </div>
  );
}