import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Brain } from 'lucide-react';
import { useFlashcardState } from '../hooks/useFlashcardState';
import { FlashcardCard } from '../components/flashcards/FlashcardCard';
import { FlashcardNavigation } from '../components/flashcards/FlashcardNavigation';

// Demo flashcards data
const demoFlashcards = [
  {
    id: '1',
    front_content: 'What is a tech certification?',
    back_content: 'A tech certification is a professional credential that validates your skills and knowledge in a specific technology, platform, or methodology. It typically requires passing an exam and may need to be renewed periodically.',
    topic_id: 'c22e37a9-6d87-4f32-97d5-aee77a78e256',
    category_id: 'f8d9e4c1-b5e6-4b0a-9e1d-7c8b2a7f3c5d',
    created_at: new Date().toISOString()
  },
  {
    id: '2',
    front_content: 'What are the benefits of getting certified?',
    back_content: '1. Validates your expertise\n2. Increases job opportunities\n3. Higher salary potential\n4. Professional credibility\n5. Structured learning path\n6. Industry recognition',
    topic_id: 'c22e37a9-6d87-4f32-97d5-aee77a78e256',
    category_id: 'f8d9e4c1-b5e6-4b0a-9e1d-7c8b2a7f3c5d',
    created_at: new Date().toISOString()
  },
  {
    id: '3',
    front_content: 'What are common types of certification questions?',
    back_content: '1. Multiple choice\n2. Multiple answer\n3. Performance-based\n4. Scenario-based\n5. Fill in the blank\n6. Drag and drop',
    topic_id: 'c22e37a9-6d87-4f32-97d5-aee77a78e256',
    category_id: 'f8d9e4c1-b5e6-4b0a-9e1d-7c8b2a7f3c5d',
    created_at: new Date().toISOString()
  },
  {
    id: '4',
    front_content: 'What is the typical exam preparation process?',
    back_content: '1. Review exam objectives\n2. Study official documentation\n3. Take practice tests\n4. Use flashcards for key concepts\n5. Join study groups\n6. Hands-on practice\n7. Review weak areas',
    topic_id: 'c22e37a9-6d87-4f32-97d5-aee77a78e256',
    category_id: 'f8d9e4c1-b5e6-4b0a-9e1d-7c8b2a7f3c5d',
    created_at: new Date().toISOString()
  },
  {
    id: '5',
    front_content: 'What are key exam day tips?',
    back_content: '1. Get plenty of rest\n2. Arrive early\n3. Read questions carefully\n4. Manage time wisely\n5. Mark questions for review\n6. Review answers if time permits\n7. Stay calm and focused',
    topic_id: 'c22e37a9-6d87-4f32-97d5-aee77a78e256',
    category_id: 'f8d9e4c1-b5e6-4b0a-9e1d-7c8b2a7f3c5d',
    created_at: new Date().toISOString()
  }
];

const demoTopic = {
  id: 'c22e37a9-6d87-4f32-97d5-aee77a78e256',
  name: 'Tech Certification Basics',
  description: 'Introduction to tech certifications and exam preparation',
  category_id: 'f8d9e4c1-b5e6-4b0a-9e1d-7c8b2a7f3c5d',
  category_name: 'Demo',
  category_description: 'Demo category for trying out flashcards'
};

export function DemoFlashcards() {
  const navigate = useNavigate();
  const { state, handleNext, handlePrevious, handleFlip } = useFlashcardState(demoFlashcards.length);

  return (
    <div className="max-w-3xl mx-auto space-y-8 p-6">
      <div>
        <h1 className="text-3xl font-bold mb-2">{demoTopic.name}</h1>
        <p className="text-gray-600">{demoTopic.description}</p>
      </div>

      <FlashcardCard
        flashcard={demoFlashcards[state.currentIndex]}
        isFlipped={state.isFlipped}
        onFlip={handleFlip}
      />

      <FlashcardNavigation
        currentIndex={state.currentIndex}
        totalCards={demoFlashcards.length}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />

      <div className="text-center mt-8">
        <p className="text-gray-600 mb-4">
          Like what you see? Sign up to access all our flashcards and study materials!
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            onClick={() => navigate('/register')}
            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium"
          >
            Create Free Account
          </button>
          <button
            onClick={() => navigate('/certifications')}
            className="px-6 py-3 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 font-medium flex items-center justify-center"
          >
            <Brain className="h-5 w-5 mr-2" />
            Browse All Topics
          </button>
        </div>
      </div>
    </div>
  );
}