import React, { useState, useEffect } from 'react';
import { AlertCircle } from 'lucide-react';
import { useQuestionManager } from '../hooks/useQuestionManager';
import { useTopics } from '../hooks/useTopics';
import { useCategories } from '../hooks/useCategories';
import { QuestionFilterBar } from '../../../components/admin/questions/QuestionFilterBar';
import { QuestionView } from '../../../components/admin/questions/QuestionView';
import { QuestionForm } from '../../../components/admin/questions/QuestionForm';
import type { Question } from '../../../types/question';

export function QuestionEditor() {
  const { questions, loading, updateQuestion, deleteQuestion, bulkDeleteQuestions, fetchQuestions } = useQuestionManager();
  const { topics } = useTopics();
  const { categories } = useCategories();
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (selectedTopicId) {
      fetchQuestions(selectedTopicId);
    } else {
      fetchQuestions();
    }
  }, [selectedTopicId]);

  const handleSave = async (question: Question) => {
    try {
      await updateQuestion(question.id, question);
      setEditingQuestion(null);
      setError(null);
    } catch (error: any) {
      setError(error.message || 'Failed to save question');
    }
  };

  const handleDelete = async (questionId: string) => {
    if (!selectedTopicId) return;
    
    if (confirm('Are you sure you want to delete this question?')) {
      try {
        await deleteQuestion(questionId, selectedTopicId);
        setError(null);
      } catch (error: any) {
        setError(error.message || 'Failed to delete question');
      }
    }
  };

  const handleBulkDelete = async () => {
    if (!selectedTopicId) return;
    
    if (selectedQuestions.length === 0) {
      setError('Please select questions to delete');
      return;
    }

    if (confirm(`Are you sure you want to delete ${selectedQuestions.length} questions?`)) {
      try {
        await bulkDeleteQuestions(selectedQuestions, selectedTopicId);
        setSelectedQuestions([]);
        setError(null);
      } catch (error: any) {
        setError(error.message || 'Failed to delete questions');
      }
    }
  };

  const toggleQuestionSelection = (questionId: string) => {
    setSelectedQuestions(prev => 
      prev.includes(questionId)
        ? prev.filter(id => id !== questionId)
        : [...prev, questionId]
    );
  };

  const selectAllQuestions = () => {
    if (selectedQuestions.length === filteredQuestions.length) {
      setSelectedQuestions([]);
    } else {
      setSelectedQuestions(filteredQuestions.map(q => q.id));
    }
  };

  const filteredQuestions = questions.filter(q => {
    const matchesSearch = q.question_text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.explanation?.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="flex flex-col space-y-4 mb-6">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold">Question Manager</h2>
            {selectedQuestions.length > 0 && (
              <button
                onClick={handleBulkDelete}
                className="flex items-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Delete Selected ({selectedQuestions.length})
              </button>
            )}
          </div>

          <QuestionFilterBar
            searchTerm={searchTerm}
            selectedCategoryId={selectedCategoryId}
            selectedTopicId={selectedTopicId}
            onSearchChange={setSearchTerm}
            onCategoryChange={(value) => {
              setSelectedCategoryId(value);
              setSelectedTopicId('');
            }}
            onTopicChange={setSelectedTopicId}
            onClearFilters={() => {
              setSearchTerm('');
              setSelectedCategoryId('');
              setSelectedTopicId('');
            }}
            categories={categories}
            topics={topics}
          />
          
          {error && (
            <div className="bg-red-50 text-red-700 p-4 rounded-md flex items-center">
              <AlertCircle className="h-5 w-5 mr-2" />
              {error}
            </div>
          )}
        </div>

        {!selectedTopicId ? (
          <div className="text-center py-8 text-gray-500">
            Please select a category and topic to view and manage questions
          </div>
        ) : loading ? (
          <div className="text-center py-4">
            <div className="inline-flex items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
              <span className="ml-3">Loading questions...</span>
            </div>
          </div>
        ) : filteredQuestions.length === 0 ? (
          <div className="text-center py-4 text-gray-500">
            No questions found matching the current filters
          </div>
        ) : (
          <div className="space-y-4">
            {/* Select All Checkbox */}
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={selectedQuestions.length === filteredQuestions.length}
                onChange={selectAllQuestions}
                className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
              <label className="ml-2 text-sm text-gray-700">
                Select All Questions
              </label>
            </div>

            {filteredQuestions.map((question, index) => (
              <div key={question.id} className="border rounded-lg p-4">
                {editingQuestion?.id === question.id ? (
                  <QuestionForm
                    question={editingQuestion}
                    onSave={handleSave}
                    onCancel={() => setEditingQuestion(null)}
                    topics={topics}
                  />
                ) : (
                  <QuestionView
                    question={question}
                    index={index}
                    isSelected={selectedQuestions.includes(question.id)}
                    onToggleSelect={toggleQuestionSelection}
                    onEdit={setEditingQuestion}
                    onDelete={handleDelete}
                    topics={topics}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}