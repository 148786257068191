import React from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import { useSubscriptionStore } from '../store/subscription';
import { 
  GraduationCap, 
  Home,
  Award,
  UserCog,
  LogOut,
  User,
  CreditCard
} from 'lucide-react';
import { Footer } from './Footer';

export function Layout() {
  const { isAdmin, signOut } = useAuthStore();
  const { hasSubscription } = useSubscriptionStore();
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  const navItems = [
    {
      path: '/',
      label: 'Home',
      icon: Home,
      show: true
    },
    {
      path: '/certifications',
      label: 'Certifications',
      icon: Award,
      show: true
    },
    {
      path: '/profile',
      label: 'Profile',
      icon: User,
      show: true
    },
    {
      path: '/subscription',
      label: 'Subscription',
      icon: CreditCard,
      show: !hasSubscription
    },
    {
      path: '/admin',
      label: 'Admin',
      icon: UserCog,
      show: isAdmin
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Top Navigation Bar */}
      <nav className="bg-white shadow-sm fixed w-full top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="flex items-center px-2 py-2 text-gray-900">
                <GraduationCap className="h-6 w-6 mr-2 text-blue-600" />
                <span className="font-semibold">ExamEngines.com</span>
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden sm:flex sm:space-x-8">
              {navItems
                .filter(item => item.show)
                .map(item => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                      isActive(item.path)
                        ? 'border-blue-500 text-gray-900'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                  >
                    <item.icon className="h-4 w-4 mr-2" />
                    {item.label}
                  </Link>
                ))}
            </div>

            <div className="flex items-center">
              <button
                onClick={handleSignOut}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-100"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation */}
      <div className="sm:hidden fixed bottom-0 left-0 right-0 bg-white border-t z-50">
        <div className="grid grid-cols-4 gap-1">
          {navItems
            .filter(item => item.show)
            .map(item => (
              <Link
                key={item.path}
                to={item.path}
                className={`flex flex-col items-center py-2 px-1 text-xs ${
                  isActive(item.path)
                    ? 'text-blue-600'
                    : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                <item.icon className="h-5 w-5 mb-1" />
                {item.label}
              </Link>
            ))}
        </div>
      </div>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 pt-20 pb-20 sm:pb-8 flex-grow">
        <Outlet />
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}