import { supabase } from '../lib/supabase';
import { Flashcard, Topic } from '../types/flashcard';
import { ensureAuthenticated } from '../lib/supabase';

export class FlashcardService {
  private static instance: FlashcardService;
  private maxRetries = 3;
  private retryDelay = 1000; // 1 second
  private connectionTimeout = 5000; // 5 seconds

  private constructor() {}

  public static getInstance(): FlashcardService {
    if (!FlashcardService.instance) {
      FlashcardService.instance = new FlashcardService();
    }
    return FlashcardService.instance;
  }

  private async checkConnection(): Promise<boolean> {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), this.connectionTimeout);

      const { data, error } = await supabase
        .from('categories')
        .select('count', { count: 'exact', head: true })
        .abortSignal(controller.signal);

      clearTimeout(timeoutId);

      if (error) {
        console.error('Connection check failed:', error);
        return false;
      }

      return true;
    } catch (error) {
      console.error('Connection check error:', error);
      return false;
    }
  }

  private async retryOperation<T>(operation: () => Promise<T>, context: string): Promise<T> {
    let lastError: any;

    // Check connection first
    const isConnected = await this.checkConnection();
    if (!isConnected) {
      throw new Error(`Unable to connect to the server. Please check your internet connection and try again. (${context})`);
    }

    for (let attempt = 1; attempt <= this.maxRetries; attempt++) {
      try {
        return await operation();
      } catch (error: any) {
        lastError = error;
        console.error(`Attempt ${attempt} failed for ${context}:`, error);

        if (error.message?.includes('Failed to fetch') || error.code === 'ECONNABORTED') {
          throw new Error('Network connection lost. Please check your internet connection and try again.');
        }

        if (attempt < this.maxRetries) {
          await new Promise(resolve => setTimeout(resolve, this.retryDelay * attempt));
          continue;
        }
        
        // On final attempt, throw a user-friendly error
        throw new Error(`Operation failed after ${this.maxRetries} attempts. Please try again later. (${context})`);
      }
    }
    throw lastError;
  }

  private parseFlashcards(text: string): { front_content: string; back_content: string }[] {
    const flashcards: { front_content: string; back_content: string }[] = [];
    const lines = text.split('\n');
    let currentCard: { front_content: string; back_content: string } | null = null;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      
      if (!line) continue;

      if (line.toLowerCase().startsWith('front:')) {
        // Save previous card if exists
        if (currentCard?.front_content && currentCard?.back_content) {
          flashcards.push({ ...currentCard });
        }
        
        // Start new card
        currentCard = {
          front_content: line.substring(6).trim(),
          back_content: ''
        };
      } else if (line.toLowerCase().startsWith('back:') && currentCard) {
        // For back content, preserve the entire line after "Back:" including any backticks
        currentCard.back_content = line.substring(5).trim();
        
        // If this is the last line or next line starts a new front, save the card
        const isLastLine = i === lines.length - 1;
        const nextLineStartsNewCard = !isLastLine && 
          lines[i + 1].trim().toLowerCase().startsWith('front:');
        
        if (isLastLine || nextLineStartsNewCard) {
          if (currentCard.front_content && currentCard.back_content) {
            flashcards.push({ ...currentCard });
            currentCard = null;
          }
        }
      } else if (currentCard) {
        // Append content to the current section, preserving any backticks
        if (currentCard.back_content) {
          currentCard.back_content += '\n' + line;
        } else {
          currentCard.front_content += '\n' + line;
        }
      }
    }

    // Add the last card if it exists and wasn't added
    if (currentCard?.front_content && currentCard?.back_content) {
      flashcards.push({ ...currentCard });
    }

    // Validate flashcards
    if (flashcards.length === 0) {
      throw new Error('No valid flashcards found. Please ensure each flashcard has a Front: and Back: section.');
    }

    // Validate content
    for (const card of flashcards) {
      if (!card.front_content.trim() || !card.back_content.trim()) {
        throw new Error('Invalid flashcard format. Both front and back content must not be empty.');
      }
    }

    return flashcards;
  }

  public async createFlashcards(topicId: string, categoryId: string, flashcardsText: string): Promise<void> {
    try {
      const flashcards = this.parseFlashcards(flashcardsText);

      if (flashcards.length === 0) {
        throw new Error('No valid flashcards found. Please check the format and try again.');
      }

      const flashcardsWithIds = flashcards.map(card => ({
        ...card,
        topic_id: topicId,
        category_id: categoryId
      }));

      await this.retryOperation(async () => {
        const { error } = await supabase
          .from('flashcards')
          .insert(flashcardsWithIds);

        if (error) throw error;
      }, 'create flashcards');
    } catch (error: any) {
      console.error('Error creating flashcards:', error);
      throw new Error(error.message || 'Failed to create flashcards. Please check your connection and try again.');
    }
  }

  public async getFlashcardsForTopic(topicId: string): Promise<Flashcard[]> {
    return this.retryOperation(async () => {
      const { data, error } = await supabase
        .rpc('get_flashcards_for_topic', { p_topic_id: topicId });

      if (error) throw error;
      return data || [];
    }, 'get flashcards for topic');
  }

  public async getTopicDetails(topicId: string): Promise<Topic> {
    return this.retryOperation(async () => {
      const { data, error } = await supabase
        .rpc('get_topic_details', { p_topic_id: topicId });

      if (error) throw error;
      if (!data || data.length === 0) {
        throw new Error('Topic not found');
      }
      return data[0];
    }, 'get topic details');
  }

  public async getFlashcardsForAdmin(topicId?: string, categoryId?: string): Promise<Flashcard[]> {
    return this.retryOperation(async () => {
      const { data, error } = await supabase
        .rpc('get_admin_flashcards', {
          p_topic_id: topicId || null,
          p_category_id: categoryId || null
        });

      if (error) throw error;
      return data || [];
    }, 'get admin flashcards');
  }

  public async updateFlashcard(flashcardId: string, updates: Partial<Flashcard>): Promise<void> {
    return this.retryOperation(async () => {
      const { error } = await supabase
        .from('flashcards')
        .update({
          front_content: updates.front_content,
          back_content: updates.back_content,
          topic_id: updates.topic_id,
          category_id: updates.category_id
        })
        .eq('id', flashcardId);

      if (error) throw error;
    }, 'update flashcard');
  }

  public async deleteFlashcard(flashcardId: string): Promise<void> {
    return this.retryOperation(async () => {
      const { error } = await supabase
        .from('flashcards')
        .delete()
        .eq('id', flashcardId);

      if (error) throw error;
    }, 'delete flashcard');
  }

  public async bulkDeleteFlashcards(flashcardIds: string[]): Promise<void> {
    if (!flashcardIds.length) {
      throw new Error('No flashcards selected for deletion');
    }
    
    return this.retryOperation(async () => {
      const { error } = await supabase
        .from('flashcards')
        .delete()
        .in('id', flashcardIds);

      if (error) throw error;
    }, 'bulk delete flashcards');
  }
}