import React, { useState } from 'react';
import { Plus, Edit, Trash2, ChevronDown, ChevronRight, HelpCircle, Brain, AlertCircle, Shield, Cloud, Network, Award } from 'lucide-react';
import { useCategories } from '../hooks/useCategories';
import { useTopics } from '../hooks/useTopics';
import { useQuestionStatistics } from '../../../hooks/useQuestionStatistics';
import { useFlashcardStatistics } from '../../../hooks/useFlashcardStatistics';

export function CategoryManager() {
  const { categories, addCategory, updateCategory, deleteCategory } = useCategories();
  const { topics, addTopic, updateTopic, deleteTopic } = useTopics();
  const { getCountForTopic: getQuestionCount } = useQuestionStatistics();
  const { getCountForTopic: getFlashcardCount } = useFlashcardStatistics();
  const [newCategory, setNewCategory] = useState({ name: '', description: '' });
  const [newTopic, setNewTopic] = useState({ name: '', description: '', category_id: '' });
  const [editingCategory, setEditingCategory] = useState<any>(null);
  const [editingTopic, setEditingTopic] = useState<any>(null);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const toggleCategory = (categoryId: string) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
  };

  const getTopicsForCategory = (categoryId: string) => {
    return topics.filter(topic => topic.category_id === categoryId);
  };

  const handleAddTopic = async () => {
    try {
      setError(null);
      if (!newTopic.name.trim()) {
        setError('Topic name is required');
        return;
      }

      await addTopic({
        name: newTopic.name.trim(),
        description: newTopic.description.trim(),
        category_id: newTopic.category_id
      });

      setNewTopic({ name: '', description: '', category_id: '' });
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleUpdateTopic = async () => {
    try {
      setError(null);
      if (!editingTopic?.name.trim()) {
        setError('Topic name is required');
        return;
      }

      await updateTopic({
        ...editingTopic,
        name: editingTopic.name.trim(),
        description: editingTopic.description.trim()
      });

      setEditingTopic(null);
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className="space-y-6">
      {/* Add Category Form */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-4">Add New Category</h3>
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="Category name"
            value={newCategory.name}
            onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
            className="flex-1 px-3 py-2 border rounded-md"
          />
          <input
            type="text"
            placeholder="Description"
            value={newCategory.description}
            onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
            className="flex-1 px-3 py-2 border rounded-md"
          />
          <button
            onClick={() => {
              if (newCategory.name) {
                addCategory(newCategory);
                setNewCategory({ name: '', description: '' });
              }
            }}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Plus className="h-5 w-5" />
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-md flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}

      {/* Categories and Topics List */}
      <div className="space-y-4">
        {categories.map(category => (
          <div key={category.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <button
              onClick={() => toggleCategory(category.id)}
              className="w-full flex items-center justify-between p-6 hover:bg-gray-50"
            >
              <div className="flex items-center space-x-4">
                {getCategoryIcon(category.name)}
                <div className="text-left">
                  <h2 className="text-xl font-semibold text-gray-900">{category.name}</h2>
                  <p className="text-gray-600">{category.description}</p>
                </div>
              </div>
              {expandedCategory === category.id ? (
                <ChevronDown className="h-5 w-5 text-gray-400" />
              ) : (
                <ChevronRight className="h-5 w-5 text-gray-400" />
              )}
            </button>

            {expandedCategory === category.id && (
              <div className="border-t border-gray-200">
                <div className="divide-y divide-gray-200">
                  {/* Add Topic Form */}
                  <div className="mb-4 p-4 bg-gray-50 rounded-md">
                    <h5 className="text-sm font-medium text-gray-700 mb-2">Add New Topic</h5>
                    <div className="flex gap-2">
                      <input
                        type="text"
                        placeholder="Topic name"
                        value={newTopic.name}
                        onChange={(e) => setNewTopic({ ...newTopic, name: e.target.value, category_id: category.id })}
                        className="flex-1 px-3 py-2 border rounded-md text-sm"
                      />
                      <input
                        type="text"
                        placeholder="Description"
                        value={newTopic.description}
                        onChange={(e) => setNewTopic({ ...newTopic, description: e.target.value, category_id: category.id })}
                        className="flex-1 px-3 py-2 border rounded-md text-sm"
                      />
                      <button
                        onClick={handleAddTopic}
                        className="px-3 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                      >
                        <Plus className="h-4 w-4" />
                      </button>
                    </div>
                  </div>

                  {/* Topics List */}
                  <div className="space-y-2">
                    {getTopicsForCategory(category.id).length === 0 ? (
                      <p className="text-sm text-gray-500 text-center py-4">
                        No topics yet. Add your first topic above.
                      </p>
                    ) : (
                      <div className="grid gap-2">
                        {getTopicsForCategory(category.id).map((topic) => {
                          const questionCount = getQuestionCount(topic.id);
                          const flashcardCount = getFlashcardCount(topic.id);
                          return (
                            <div
                              key={topic.id}
                              className="flex items-center justify-between p-3 bg-gray-50 rounded-md hover:bg-gray-100"
                            >
                              <div className="flex-1">
                                <div className="flex items-center gap-2">
                                  <h6 className="font-medium">{topic.name}</h6>
                                  <div className="flex gap-2">
                                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                                      <HelpCircle className="h-3 w-3 mr-1" />
                                      {questionCount} questions
                                    </span>
                                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
                                      <Brain className="h-3 w-3 mr-1" />
                                      {flashcardCount} flashcards
                                    </span>
                                  </div>
                                </div>
                                <p className="text-sm text-gray-600">{topic.description}</p>
                              </div>
                              <div className="flex gap-2">
                                <button
                                  onClick={() => setEditingTopic(topic)}
                                  className="p-1 text-blue-600 hover:text-blue-800"
                                >
                                  <Edit className="h-4 w-4" />
                                </button>
                                <button
                                  onClick={() => deleteTopic(topic.id)}
                                  className="p-1 text-red-600 hover:text-red-800"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Edit Category Modal */}
      {editingCategory && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Edit Category</h3>
            <div className="space-y-4">
              <input
                type="text"
                value={editingCategory.name}
                onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Category name"
              />
              <input
                type="text"
                value={editingCategory.description}
                onChange={(e) => setEditingCategory({ ...editingCategory, description: e.target.value })}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Description"
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setEditingCategory(null)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    updateCategory(editingCategory);
                    setEditingCategory(null);
                  }}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Topic Modal */}
      {editingTopic && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Edit Topic</h3>
            <div className="space-y-4">
              <select
                value={editingTopic.category_id}
                onChange={(e) => setEditingTopic({ ...editingTopic, category_id: e.target.value })}
                className="w-full px-3 py-2 border rounded-md"
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                value={editingTopic.name}
                onChange={(e) => setEditingTopic({ ...editingTopic, name: e.target.value })}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Topic name"
              />
              <input
                type="text"
                value={editingTopic.description}
                onChange={(e) => setEditingTopic({ ...editingTopic, description: e.target.value })}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Description"
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => {
                    setEditingTopic(null);
                    setError(null);
                  }}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateTopic}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function getCategoryIcon(categoryName: string) {
  switch (categoryName.toLowerCase()) {
    case 'security':
      return <Shield className="h-6 w-6 text-blue-600" />;
    case 'cloud computing':
      return <Cloud className="h-6 w-6 text-purple-600" />;
    case 'networking':
      return <Network className="h-6 w-6 text-green-600" />;
    default:
      return <Award className="h-6 w-6 text-orange-600" />;
  }
}