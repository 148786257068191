import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface FlashcardNavigationProps {
  currentIndex: number;
  totalCards: number;
  onPrevious: () => void;
  onNext: () => void;
}

export function FlashcardNavigation({
  currentIndex,
  totalCards,
  onPrevious,
  onNext
}: FlashcardNavigationProps) {
  return (
    <div className="flex items-center justify-between mt-6">
      <button
        onClick={onPrevious}
        disabled={currentIndex === 0}
        className={`flex items-center px-4 py-2 rounded-md ${
          currentIndex === 0
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        }`}
      >
        <ChevronLeft className="h-5 w-5 mr-1" />
        Previous
      </button>

      <div className="text-gray-600">
        {currentIndex + 1} of {totalCards}
      </div>

      <button
        onClick={onNext}
        disabled={currentIndex === totalCards - 1}
        className={`flex items-center px-4 py-2 rounded-md ${
          currentIndex === totalCards - 1
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        }`}
      >
        Next
        <ChevronRight className="h-5 w-5 ml-1" />
      </button>
    </div>
  );
}