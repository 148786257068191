import React, { useState, useEffect } from 'react';
import { Edit2, Save, AlertCircle, Search } from 'lucide-react';
import { supabase } from '../../../lib/supabase';

interface SeoData {
  id: string;
  title: string;
  slug: string;
  meta_title: string;
  meta_description: string;
  og_title: string;
  og_description: string;
  og_image: string;
  twitter_title: string;
  twitter_description: string;
  twitter_image: string;
  updated_at: string;
}

export function SeoManager() {
  const [pages, setPages] = useState<SeoData[]>([]);
  const [editingPage, setEditingPage] = useState<SeoData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('content_pages')
        .select('id, title, slug, meta_title, meta_description, og_title, og_description, og_image, twitter_title, twitter_description, twitter_image, updated_at')
        .order('title', { ascending: true });

      if (error) throw error;
      setPages(data || []);
    } catch (err: any) {
      console.error('Error fetching pages:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!editingPage) return;

    try {
      const { error } = await supabase
        .from('content_pages')
        .update({
          meta_title: editingPage.meta_title,
          meta_description: editingPage.meta_description,
          og_title: editingPage.og_title,
          og_description: editingPage.og_description,
          og_image: editingPage.og_image,
          twitter_title: editingPage.twitter_title,
          twitter_description: editingPage.twitter_description,
          twitter_image: editingPage.twitter_image,
          updated_at: new Date().toISOString()
        })
        .eq('id', editingPage.id);

      if (error) throw error;

      setSuccess('SEO data updated successfully');
      setEditingPage(null);
      fetchPages();
    } catch (err: any) {
      console.error('Error updating SEO data:', err);
      setError(err.message);
    }
  };

  const filteredPages = pages.filter(page => 
    page.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    page.slug.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          <span className="ml-3">Loading pages...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">SEO Management</h2>
          <div className="relative">
            <input
              type="text"
              placeholder="Search pages..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md">
            {success}
          </div>
        )}

        <div className="space-y-6">
          {filteredPages.map(page => (
            <div key={page.id} className="border rounded-lg p-4">
              {editingPage?.id === page.id ? (
                <div className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <h3 className="font-medium mb-2">Meta Tags</h3>
                      <div className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Meta Title
                          </label>
                          <input
                            type="text"
                            value={editingPage.meta_title || ''}
                            onChange={(e) => setEditingPage({
                              ...editingPage,
                              meta_title: e.target.value
                            })}
                            className="w-full px-3 py-2 border rounded-md"
                            maxLength={60}
                          />
                          <p className="mt-1 text-sm text-gray-500">
                            {(editingPage.meta_title?.length || 0)}/60 characters
                          </p>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Meta Description
                          </label>
                          <textarea
                            value={editingPage.meta_description || ''}
                            onChange={(e) => setEditingPage({
                              ...editingPage,
                              meta_description: e.target.value
                            })}
                            className="w-full px-3 py-2 border rounded-md"
                            rows={3}
                            maxLength={160}
                          />
                          <p className="mt-1 text-sm text-gray-500">
                            {(editingPage.meta_description?.length || 0)}/160 characters
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h3 className="font-medium mb-2">Open Graph</h3>
                      <div className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            OG Title
                          </label>
                          <input
                            type="text"
                            value={editingPage.og_title || ''}
                            onChange={(e) => setEditingPage({
                              ...editingPage,
                              og_title: e.target.value
                            })}
                            className="w-full px-3 py-2 border rounded-md"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            OG Description
                          </label>
                          <textarea
                            value={editingPage.og_description || ''}
                            onChange={(e) => setEditingPage({
                              ...editingPage,
                              og_description: e.target.value
                            })}
                            className="w-full px-3 py-2 border rounded-md"
                            rows={3}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            OG Image URL
                          </label>
                          <input
                            type="url"
                            value={editingPage.og_image || ''}
                            onChange={(e) => setEditingPage({
                              ...editingPage,
                              og_image: e.target.value
                            })}
                            className="w-full px-3 py-2 border rounded-md"
                            placeholder="https://example.com/image.jpg"
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <h3 className="font-medium mb-2">Twitter Card</h3>
                      <div className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Twitter Title
                          </label>
                          <input
                            type="text"
                            value={editingPage.twitter_title || ''}
                            onChange={(e) => setEditingPage({
                              ...editingPage,
                              twitter_title: e.target.value
                            })}
                            className="w-full px-3 py-2 border rounded-md"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Twitter Description
                          </label>
                          <textarea
                            value={editingPage.twitter_description || ''}
                            onChange={(e) => setEditingPage({
                              ...editingPage,
                              twitter_description: e.target.value
                            })}
                            className="w-full px-3 py-2 border rounded-md"
                            rows={3}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Twitter Image URL
                          </label>
                          <input
                            type="url"
                            value={editingPage.twitter_image || ''}
                            onChange={(e) => setEditingPage({
                              ...editingPage,
                              twitter_image: e.target.value
                            })}
                            className="w-full px-3 py-2 border rounded-md"
                            placeholder="https://example.com/image.jpg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end gap-2 pt-4">
                    <button
                      onClick={() => setEditingPage(null)}
                      className="px-4 py-2 text-gray-600 hover:text-gray-800"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                      <Save className="h-4 w-4 mr-2" />
                      Save Changes
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <div>
                      <h3 className="text-lg font-medium">{page.title}</h3>
                      <p className="text-sm text-gray-500">/{page.slug}</p>
                    </div>
                    <button
                      onClick={() => setEditingPage(page)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 mb-1">Meta Title</h4>
                      <p className="text-sm text-gray-600">{page.meta_title || '-'}</p>
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 mb-1">Meta Description</h4>
                      <p className="text-sm text-gray-600">{page.meta_description || '-'}</p>
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 mb-1">Last Updated</h4>
                      <p className="text-sm text-gray-600">
                        {new Date(page.updated_at).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}