import React, { useState } from 'react';
import { Brain, AlertCircle } from 'lucide-react';
import { useCategories } from '../hooks/useCategories';
import { useTopics } from '../hooks/useTopics';
import { FlashcardService } from '../../../services/FlashcardService';

export function FlashcardUploader() {
  const { categories } = useCategories();
  const { topics } = useTopics();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [bulkFlashcards, setBulkFlashcards] = useState('');
  const [uploadStatus, setUploadStatus] = useState<{ success: boolean; message: string } | null>(null);
  const flashcardService = FlashcardService.getInstance();

  const handleUpload = async () => {
    if (!selectedTopicId || !selectedCategoryId) {
      setUploadStatus({
        success: false,
        message: 'Please select both a category and topic for the flashcards.'
      });
      return;
    }

    try {
      await flashcardService.createFlashcards(selectedTopicId, selectedCategoryId, bulkFlashcards);
      setUploadStatus({
        success: true,
        message: 'Flashcards uploaded successfully!'
      });
      setBulkFlashcards('');
    } catch (error: any) {
      setUploadStatus({
        success: false,
        message: error.message || 'Error uploading flashcards. Please try again.'
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6">
          <div className="flex items-center mb-4">
            <Brain className="h-6 w-6 text-purple-600 mr-2" />
            <h2 className="text-2xl font-semibold">Bulk Upload Flashcards</h2>
          </div>

          <div className="space-y-4">
            {/* Topic Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Select Category and Topic</label>
              <div className="grid grid-cols-2 gap-4">
                <select
                  value={selectedCategoryId}
                  onChange={(e) => {
                    setSelectedCategoryId(e.target.value);
                    setSelectedTopicId('');
                  }}
                  className="px-3 py-2 border rounded-md"
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <select
                  value={selectedTopicId}
                  onChange={(e) => setSelectedTopicId(e.target.value)}
                  className="px-3 py-2 border rounded-md"
                  disabled={!selectedCategoryId}
                >
                  <option value="">Select Topic</option>
                  {topics
                    .filter(topic => topic.category_id === selectedCategoryId)
                    .map((topic) => (
                      <option key={topic.id} value={topic.id}>
                        {topic.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="bg-gray-50 p-4 rounded-md">
              <h4 className="font-medium text-gray-700 mb-2">Format Example:</h4>
              <pre className="text-sm text-gray-600 whitespace-pre-wrap">
{`Front: What is Infrastructure as Code (IaC)?
Back: A practice where infrastructure is provisioned and managed using code and software development techniques

Front: What are the benefits of using Terraform?
Back: - Declarative configuration
- Infrastructure version control
- Reusable modules
- Multi-cloud support
- Consistent infrastructure

Front: What is a VPC?
Back: A Virtual Private Cloud (VPC) is an isolated section of the cloud where you can launch resources in a defined virtual network`}
              </pre>
            </div>

            <textarea
              value={bulkFlashcards}
              onChange={(e) => setBulkFlashcards(e.target.value)}
              placeholder="Paste your flashcards here..."
              className="w-full h-64 p-4 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />

            {uploadStatus && (
              <div className={`p-4 rounded-md flex items-center ${
                uploadStatus.success ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
              }`}>
                {!uploadStatus.success && <AlertCircle className="h-5 w-5 mr-2" />}
                {uploadStatus.message}
              </div>
            )}

            <button
              onClick={handleUpload}
              disabled={!selectedTopicId || !selectedCategoryId}
              className={`flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
                (!selectedTopicId || !selectedCategoryId) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <Brain className="h-5 w-5 mr-2" />
              Upload Flashcards
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}