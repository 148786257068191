import React from 'react';
import { Rotate3D } from 'lucide-react';
import { Flashcard } from '../../types/flashcard';

interface FlashcardCardProps {
  flashcard: Flashcard;
  isFlipped: boolean;
  onFlip: () => void;
}

export function FlashcardCard({ flashcard, isFlipped, onFlip }: FlashcardCardProps) {
  const formatContent = (content: string) => {
    return content.split('\n').map((line, i) => {
      const codeRegex = /`([^`]+)`/g;
      const parts = line.split(codeRegex);
      
      return (
        <React.Fragment key={i}>
          {parts.map((part, j) => {
            if (j % 2 === 1) {
              return (
                <code key={j} className="px-1.5 py-0.5 bg-gray-100 rounded font-mono text-sm">
                  {part}
                </code>
              );
            }
            return part;
          })}
          {i < content.split('\n').length - 1 && <br />}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="space-y-4">
      <div
        className="relative h-[400px] w-full perspective-1000 cursor-pointer"
        onClick={onFlip}
      >
        <div
          className={`absolute inset-0 w-full h-full duration-500 preserve-3d ${
            isFlipped ? 'rotate-y-180' : ''
          }`}
        >
          {/* Front of card */}
          <div className="absolute inset-0 backface-hidden">
            <div className="h-full bg-white rounded-lg shadow-lg p-8 flex items-center justify-center">
              <div className="text-center">
                <div className="text-xl whitespace-pre-wrap">
                  {formatContent(flashcard.front_content)}
                </div>
                <div className="absolute bottom-4 left-0 right-0 text-center">
                  <p className="text-sm text-gray-500">Click to flip</p>
                </div>
              </div>
            </div>
          </div>

          {/* Back of card */}
          <div className="absolute inset-0 backface-hidden rotate-y-180">
            <div className="h-full bg-blue-50 rounded-lg shadow-lg p-8 flex items-center justify-center">
              <div className="text-center">
                <div className="text-xl whitespace-pre-wrap">
                  {formatContent(flashcard.back_content)}
                </div>
                <div className="absolute bottom-4 left-0 right-0 text-center">
                  <p className="text-sm text-gray-500">Click to flip back</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={onFlip}
        className="flex items-center mx-auto mt-4 px-4 py-2 text-blue-600 hover:text-blue-700"
      >
        <Rotate3D className="h-5 w-5 mr-2" />
        Flip Card
      </button>
    </div>
  );
}