import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings, AlertCircle } from 'lucide-react';
import { QuizSettings } from '../../types/quiz';

interface QuizSettingsProps {
  settings: QuizSettings;
  topicName: string;
  error: string | null;
  onSettingChange: (field: keyof QuizSettings, value: string) => void;
  onStart: (e: React.FormEvent) => void;
}

export function QuizSettingsForm({ settings, topicName, error, onSettingChange, onStart }: QuizSettingsProps) {
  const navigate = useNavigate();

  return (
    <div className="max-w-3xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center mb-6">
          <Settings className="h-6 w-6 text-blue-600 mr-2" />
          <h2 className="text-2xl font-semibold">Quiz Settings</h2>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 rounded-md text-red-700">
            <div className="flex items-center">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <p>{error}</p>
            </div>
          </div>
        )}

        {topicName && (
          <div className="mb-6 p-4 bg-blue-50 rounded-lg">
            <h3 className="text-lg font-medium text-blue-900">Topic: {topicName}</h3>
            <p className="text-sm text-blue-700 mt-1">Configure your quiz settings below</p>
          </div>
        )}

        <form onSubmit={onStart} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Time Limit (minutes)
            </label>
            <input
              type="number"
              min="1"
              max="180"
              value={settings.timeLimit.toString()}
              onChange={(e) => onSettingChange('timeLimit', e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
            />
            <p className="mt-1 text-sm text-gray-500">Set the time limit for the quiz (1-180 minutes)</p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Number of Questions
            </label>
            <input
              type="number"
              min="5"
              max="50"
              value={settings.questionCount.toString()}
              onChange={(e) => onSettingChange('questionCount', e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
            />
            <p className="mt-1 text-sm text-gray-500">Choose how many questions to include (5-50 questions)</p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Passing Rate (%)
            </label>
            <input
              type="number"
              min="1"
              max="100"
              value={settings.passingRate.toString()}
              onChange={(e) => onSettingChange('passingRate', e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
            />
            <p className="mt-1 text-sm text-gray-500">Set the minimum score required to pass (1-100%)</p>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/certifications')}
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!!error}
              className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
                error ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              Start Quiz
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}