import { useState, useEffect } from 'react';
import { FlashcardStatisticsService, TopicFlashcardCount } from '../services/FlashcardStatisticsService';

export function useFlashcardStatistics() {
  const [flashcardCounts, setFlashcardCounts] = useState<TopicFlashcardCount[]>([]);
  const [totalFlashcards, setTotalFlashcards] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const statisticsService = FlashcardStatisticsService.getInstance();

  const fetchStatistics = async () => {
    setLoading(true);
    setError(null);
    try {
      const [counts, total] = await Promise.all([
        statisticsService.getFlashcardCountsByTopic(),
        statisticsService.getTotalFlashcardCount()
      ]);
      setFlashcardCounts(counts);
      setTotalFlashcards(total);
    } catch (err: any) {
      console.error('Error fetching flashcard statistics:', err);
      setError('Failed to load flashcard statistics');
      setFlashcardCounts([]);
      setTotalFlashcards(0);
    } finally {
      setLoading(false);
    }
  };

  const getCountForTopic = (topicId: string): number => {
    const count = flashcardCounts.find(c => c.topic_id === topicId);
    return count ? count.count : 0;
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  return {
    flashcardCounts,
    totalFlashcards,
    loading,
    error,
    getCountForTopic,
    refreshStatistics: fetchStatistics
  };
}