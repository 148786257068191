import React, { useState } from 'react';
import { Folder, Tag, Upload, Brain, Edit2, BookOpen, FileText } from 'lucide-react';
import { CategoryManager } from './CategoryManager';
import { QuestionUploader } from './QuestionUploader';
import { QuestionEditor } from './QuestionEditor';
import { FlashcardUploader } from './FlashcardUploader';
import { FlashcardEditor } from './FlashcardEditor';
import { QuizBuilder } from './QuizBuilder';
import { FileUploader } from './FileUploader';
import { FlashcardFileUploader } from './FlashcardFileUploader';

export function ContentManager() {
  const [activeSection, setActiveSection] = useState<
    'categories' | 'questions' | 'question-editor' | 'flashcards' | 'flashcard-editor' | 'quiz-builder' | 'file-uploader' | 'flashcard-file-uploader'
  >('categories');

  return (
    <div className="space-y-6">
      <div className="flex flex-wrap gap-4 mb-6">
        <button
          onClick={() => setActiveSection('categories')}
          className={`flex items-center px-4 py-2 rounded-md ${
            activeSection === 'categories'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-600 hover:text-gray-800'
          }`}
        >
          <Folder className="h-5 w-5 mr-2" />
          Categories & Topics
        </button>
        <button
          onClick={() => setActiveSection('questions')}
          className={`flex items-center px-4 py-2 rounded-md ${
            activeSection === 'questions'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-600 hover:text-gray-800'
          }`}
        >
          <Upload className="h-5 w-5 mr-2" />
          Upload Questions
        </button>
        <button
          onClick={() => setActiveSection('file-uploader')}
          className={`flex items-center px-4 py-2 rounded-md ${
            activeSection === 'file-uploader'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-600 hover:text-gray-800'
          }`}
        >
          <FileText className="h-5 w-5 mr-2" />
          Upload Questions File
        </button>
        <button
          onClick={() => setActiveSection('question-editor')}
          className={`flex items-center px-4 py-2 rounded-md ${
            activeSection === 'question-editor'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-600 hover:text-gray-800'
          }`}
        >
          <Edit2 className="h-5 w-5 mr-2" />
          Edit Questions
        </button>
        <button
          onClick={() => setActiveSection('flashcards')}
          className={`flex items-center px-4 py-2 rounded-md ${
            activeSection === 'flashcards'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-600 hover:text-gray-800'
          }`}
        >
          <Upload className="h-5 w-5 mr-2" />
          Upload Flashcards
        </button>
        <button
          onClick={() => setActiveSection('flashcard-file-uploader')}
          className={`flex items-center px-4 py-2 rounded-md ${
            activeSection === 'flashcard-file-uploader'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-600 hover:text-gray-800'
          }`}
        >
          <FileText className="h-5 w-5 mr-2" />
          Upload Flashcards File
        </button>
        <button
          onClick={() => setActiveSection('flashcard-editor')}
          className={`flex items-center px-4 py-2 rounded-md ${
            activeSection === 'flashcard-editor'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-600 hover:text-gray-800'
          }`}
        >
          <Brain className="h-5 w-5 mr-2" />
          Edit Flashcards
        </button>
        <button
          onClick={() => setActiveSection('quiz-builder')}
          className={`flex items-center px-4 py-2 rounded-md ${
            activeSection === 'quiz-builder'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-600 hover:text-gray-800'
          }`}
        >
          <BookOpen className="h-5 w-5 mr-2" />
          Quiz Builder
        </button>
      </div>

      {activeSection === 'categories' && <CategoryManager />}
      {activeSection === 'questions' && <QuestionUploader />}
      {activeSection === 'file-uploader' && <FileUploader />}
      {activeSection === 'question-editor' && <QuestionEditor />}
      {activeSection === 'flashcards' && <FlashcardUploader />}
      {activeSection === 'flashcard-file-uploader' && <FlashcardFileUploader />}
      {activeSection === 'flashcard-editor' && <FlashcardEditor />}
      {activeSection === 'quiz-builder' && <QuizBuilder />}
    </div>
  );
}