import { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabase';

interface User {
  id: string;
  full_name: string;
  is_admin: boolean;
  created_at: string;
}

export function useUsers() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      // First check if current user is admin
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) {
        throw new Error('Not authenticated');
      }

      const { data: currentUser } = await supabase
        .from('profiles')
        .select('is_admin')
        .eq('id', session.user.id)
        .single();

      if (!currentUser?.is_admin) {
        throw new Error('Unauthorized: Admin access required');
      }

      // Fetch all users
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setUsers(data || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]); // Clear users on error
    } finally {
      setIsLoading(false);
    }
  };

  const addUser = async (email: string, password: string, fullName: string, isAdmin: boolean) => {
    try {
      // Check admin status
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) {
        throw new Error('Not authenticated');
      }

      const { data: adminCheck } = await supabase
        .from('profiles')
        .select('is_admin')
        .eq('id', session.user.id)
        .single();

      if (!adminCheck?.is_admin) {
        throw new Error('Only administrators can create new users');
      }

      // Create the user using standard signup
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: window.location.origin,
          data: {
            full_name: fullName,
            is_admin: isAdmin
          }
        }
      });

      if (signUpError) throw signUpError;
      if (!authData?.user) throw new Error('Failed to create user account');

      // Wait for the trigger to create the profile
      await new Promise(resolve => setTimeout(resolve, 1000));

      await fetchUsers();
      return true;
    } catch (error: any) {
      console.error('Error adding user:', error);
      throw new Error(error.message || 'Failed to create user');
    }
  };

  const updateUser = async (userId: string, updates: Partial<User>) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) {
        throw new Error('Not authenticated');
      }

      const { data: adminCheck } = await supabase
        .from('profiles')
        .select('is_admin')
        .eq('id', session.user.id)
        .single();

      if (!adminCheck?.is_admin) {
        throw new Error('Only administrators can update users');
      }

      // Check if trying to remove admin status from last admin
      if (updates.is_admin === false) {
        const { data: userToUpdate } = await supabase
          .from('profiles')
          .select('is_admin')
          .eq('id', userId)
          .single();

        if (userToUpdate?.is_admin) {
          const { count } = await supabase
            .from('profiles')
            .select('id', { count: 'exact', head: true })
            .eq('is_admin', true);

          if (count === 1) {
            throw new Error('Cannot remove admin status from the last admin user');
          }
        }
      }

      const { error } = await supabase
        .from('profiles')
        .update({
          ...updates,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId);

      if (error) throw error;
      await fetchUsers();
      return true;
    } catch (error: any) {
      console.error('Error updating user:', error);
      throw new Error(error.message || 'Failed to update user');
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) {
        throw new Error('Not authenticated');
      }

      const { data: adminCheck } = await supabase
        .from('profiles')
        .select('is_admin')
        .eq('id', session.user.id)
        .single();

      if (!adminCheck?.is_admin) {
        throw new Error('Only administrators can delete users');
      }

      // The trigger will handle checking for last admin
      const { error: deleteError } = await supabase
        .from('profiles')
        .delete()
        .eq('id', userId);

      if (deleteError) {
        if (deleteError.message.includes('last admin')) {
          throw new Error('Cannot delete the last admin user');
        }
        throw deleteError;
      }

      await fetchUsers();
      return true;
    } catch (error: any) {
      console.error('Error deleting user:', error);
      throw new Error(error.message || 'Failed to delete user');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return {
    users,
    isLoading,
    addUser,
    updateUser,
    deleteUser,
    refreshUsers: fetchUsers
  };
}