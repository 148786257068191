import React from 'react';
import { Flag } from 'lucide-react';
import { Question } from '../../types/quiz';

interface QuizQuestionProps {
  question: Question;
  selectedAnswerIds: string[];
  isQuestionFlagged: boolean;
  onAnswerSelect: (answerId: string) => void;
  onFlagQuestion: () => void;
}

export function QuizQuestion({
  question,
  selectedAnswerIds,
  isQuestionFlagged,
  onAnswerSelect,
  onFlagQuestion
}: QuizQuestionProps) {
  // Calculate the number of correct answers for this question
  const correctAnswersCount = question.answers.filter(a => a.is_correct).length;
  
  // Determine if we should use radio buttons (single answer) or checkboxes (multiple answers)
  const isSingleAnswer = correctAnswersCount === 1;

  // For single answer questions, ensure only one answer is selected
  const handleAnswerSelect = (answerId: string) => {
    if (isSingleAnswer) {
      // For single answer questions, directly select the new answer
      onAnswerSelect(answerId);
    } else {
      // For multiple answer questions, check if we're at the limit
      const isCurrentlySelected = selectedAnswerIds.includes(answerId);
      if (!isCurrentlySelected && selectedAnswerIds.length >= correctAnswersCount) {
        return; // Don't allow more selections than correct answers
      }
      onAnswerSelect(answerId);
    }
  };

  return (
    <div className="space-y-6">
      <div className="text-lg">{question.question_text}</div>

      {question.image_url && (
        <div className="my-4">
          <img
            src={question.image_url}
            alt="Question"
            className="max-w-full h-auto rounded-lg shadow-md"
          />
        </div>
      )}

      <div className="space-y-3">
        {question.answers.map(answer => (
          <button
            key={answer.id}
            onClick={() => handleAnswerSelect(answer.id)}
            className={`w-full text-left p-3 rounded-md border ${
              selectedAnswerIds.includes(answer.id)
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-300 hover:border-gray-400'
            }`}
          >
            <div className="flex items-center">
              {isSingleAnswer ? (
                <div className={`w-4 h-4 rounded-full border mr-3 flex-shrink-0 ${
                  selectedAnswerIds.includes(answer.id)
                    ? 'border-blue-500 bg-blue-500'
                    : 'border-gray-300'
                }`}>
                  {selectedAnswerIds.includes(answer.id) && (
                    <div className="w-2 h-2 bg-white rounded-full m-auto" />
                  )}
                </div>
              ) : (
                <div className={`w-4 h-4 rounded border mr-3 flex-shrink-0 ${
                  selectedAnswerIds.includes(answer.id)
                    ? 'border-blue-500 bg-blue-500'
                    : 'border-gray-300'
                }`}>
                  {selectedAnswerIds.includes(answer.id) && (
                    <svg
                      className="w-3 h-3 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              )}
              <span>{answer.answer_text}</span>
            </div>
          </button>
        ))}
      </div>

      <button
        onClick={onFlagQuestion}
        className={`p-2 rounded-md ${
          isQuestionFlagged ? 'text-yellow-500' : 'text-gray-400'
        } hover:text-yellow-500`}
        title={isQuestionFlagged ? 'Unflag question' : 'Flag question for review'}
      >
        <Flag className="h-5 w-5" />
      </button>
    </div>
  );
}