import { supabase } from '../lib/supabase';
import { loadStripe } from '@stripe/stripe-js';
import type { SubscriptionTier, UserSubscription } from '../types/subscription';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export class SubscriptionService {
  private static instance: SubscriptionService;
  private maxRetries = 3;
  private retryDelay = 1000; // 1 second
  private connectionTimeout = 120000; // 120 seconds
  private baseUrl: string;

  private constructor() {
    // Ensure the URL ends with /functions/v1
    const baseUrl = import.meta.env.VITE_SUPABASE_URL;
    if (!baseUrl) {
      throw new Error('VITE_SUPABASE_URL is not configured');
    }
    this.baseUrl = `${baseUrl.replace(/\/$/, '')}/functions/v1`;
  }

  public static getInstance(): SubscriptionService {
    if (!SubscriptionService.instance) {
      SubscriptionService.instance = new SubscriptionService();
    }
    return SubscriptionService.instance;
  }

  private async checkConnection(): Promise<boolean> {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), this.connectionTimeout);

      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('Please log in to continue');
      }

      const { error } = await supabase
        .from('subscription_tiers')
        .select('count', { count: 'exact', head: true })
        .abortSignal(controller.signal);

      clearTimeout(timeoutId);

      if (error) throw error;
      return true;
    } catch (error) {
      console.error('Connection check error:', error);
      return false;
    }
  }

  private async retryOperation<T>(operation: () => Promise<T>, context: string): Promise<T> {
    let lastError: any;
    let delay = this.retryDelay;

    // Check connection first
    const isConnected = await this.checkConnection();
    if (!isConnected) {
      throw new Error('Unable to connect to the server. Please check your internet connection and try again.');
    }

    for (let attempt = 1; attempt <= this.maxRetries; attempt++) {
      try {
        console.log(`${context}: Attempt ${attempt}`);
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), this.connectionTimeout);

        const result = await Promise.race([
          operation(),
          new Promise<never>((_, reject) => {
            controller.signal.addEventListener('abort', () => {
              reject(new Error('Request timed out'));
            });
          })
        ]);

        clearTimeout(timeoutId);
        console.log(`${context}: Success`);
        return result;
      } catch (error: any) {
        lastError = error;
        console.error(`${context}: Attempt ${attempt} failed:`, error);

        // Check for specific error types
        if (error.name === 'AbortError') {
          throw new Error('The request timed out. Please check your internet connection and try again.');
        }

        if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
          throw new Error('Unable to connect to the server. Please check your internet connection.');
        }

        if (error.status === 401 || error.message.includes('not authenticated')) {
          throw new Error('Your session has expired. Please log in again.');
        }

        if (error.status === 403) {
          throw new Error('You do not have permission to perform this action.');
        }

        if (attempt < this.maxRetries) {
          // Add jitter to prevent thundering herd
          const jitter = Math.random() * 200;
          delay = Math.min(delay * Math.pow(2, attempt - 1) + jitter, 10000);
          console.log(`${context}: Retrying in ${delay}ms`);
          await new Promise(resolve => setTimeout(resolve, delay));
          continue;
        }
      }
    }

    console.error(`${context}: All attempts failed`);
    throw lastError || new Error('The operation could not be completed. Please try again later.');
  }

  async getSubscriptionTiers(): Promise<SubscriptionTier[]> {
    return this.retryOperation(async () => {
      const { data, error } = await supabase
        .from('subscription_tiers')
        .select('*')
        .eq('active', true)
        .order('price_amount');

      if (error) throw error;
      return data || [];
    }, 'getSubscriptionTiers');
  }

  async getCurrentSubscription(): Promise<UserSubscription | null> {
    return this.retryOperation(async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return null;

      const { data, error } = await supabase
        .from('user_subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .eq('status', 'active')
        .maybeSingle();

      if (error && error.code !== 'PGRST116') throw error;
      return data;
    }, 'getCurrentSubscription');
  }

  async createCheckoutSession(priceId: string): Promise<string> {
    return this.retryOperation(async () => {
      console.log('Creating checkout session for price:', priceId);
      
      const { data: { session }, error: authError } = await supabase.auth.getSession();
      if (authError || !session) {
        throw new Error('Your session has expired. Please log in again.');
      }

      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError || !user) {
        throw new Error('Please log in to continue.');
      }

      // Get or create Stripe customer
      let customerId: string | undefined;
      try {
        const { data: customer, error: customerError } = await supabase
          .from('stripe_customers')
          .select('customer_id')
          .eq('id', user.id)
          .maybeSingle();

        if (customerError && customerError.code !== 'PGRST116') {
          throw customerError;
        }

        customerId = customer?.customer_id;
      } catch (err: any) {
        console.error('Error getting Stripe customer:', err);
        // Don't throw here - we'll create a new customer in the edge function
      }

      const endpoint = `${this.baseUrl}/create-checkout-session`;
      console.log('Sending request to:', endpoint);

      // Create Stripe checkout session
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
        },
        body: JSON.stringify({
          priceId,
          customerId,
          userId: user.id,
          returnUrl: window.location.origin + '/subscription'
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('Checkout session creation failed:', errorData);
        throw new Error(errorData?.error || `Failed to create checkout session (${response.status})`);
      }

      const data = await response.json();
      if (!data?.sessionId) {
        throw new Error('Invalid response from server: No session ID received');
      }

      console.log('Checkout session created successfully');
      return data.sessionId;
    }, 'createCheckoutSession');
  }

  async redirectToCheckout(priceId: string): Promise<void> {
    return this.retryOperation(async () => {
      console.log('Starting checkout process for price:', priceId);
      
      const sessionId = await this.createCheckoutSession(priceId);
      console.log('Got session ID:', sessionId);
      
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Failed to initialize payment system');
      }

      console.log('Redirecting to Stripe checkout...');
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Redirect to checkout failed:', error);
        throw error;
      }
    }, 'redirectToCheckout');
  }

  async cancelSubscription(): Promise<void> {
    return this.retryOperation(async () => {
      const { data: { session }, error: authError } = await supabase.auth.getSession();
      if (authError || !session) {
        throw new Error('Your session has expired. Please log in again.');
      }

      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError || !user) {
        throw new Error('Please log in to continue.');
      }

      const response = await fetch(`${this.baseUrl}/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
        },
        body: JSON.stringify({ userId: user.id })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || 'Failed to cancel subscription');
      }
    }, 'cancelSubscription');
  }

  async hasActiveSubscription(): Promise<boolean> {
    return this.retryOperation(async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return false;

      const { data, error } = await supabase
        .rpc('has_active_subscription', { user_id: user.id });

      if (error) throw error;
      return data || false;
    }, 'hasActiveSubscription');
  }
}