import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Timer, Settings, AlertCircle, CheckCircle, XCircle, ChevronLeft, ChevronRight, Flag, Pause, Play } from 'lucide-react';
import { QuizService } from '../services/QuizService';
import { QuizSettingsForm } from '../components/quiz/QuizSettings';
import { QuizQuestion } from '../components/quiz/QuizQuestion';
import { QuizResult } from '../components/quiz/QuizResult';
import { useQuizState } from '../hooks/useQuizState';
import { formatTime } from '../utils/formatTime';
import { AdSense } from '../components/AdSense';
import type { QuizSettings } from '../types/quiz';

export function Quiz() {
  const { id: topicId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showSettings, setShowSettings] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [settings, setSettings] = useState<QuizSettings>({
    timeLimit: 60,
    questionCount: 10,
    passingRate: 70
  });
  const [topicName, setTopicName] = useState<string>('');
  const quizService = QuizService.getInstance();
  const quizState = useQuizState(settings);
  const { state, setQuestions, selectAnswer, toggleFlaggedQuestion, calculateScore } = quizState;

  useEffect(() => {
    const fetchTopicName = async () => {
      if (!topicId) return;
      
      try {
        const name = await quizService.getTopicName(topicId);
        setTopicName(name);
      } catch (error: any) {
        console.error('Error fetching topic:', error);
        setError('Failed to load topic information');
      }
    };

    fetchTopicName();
  }, [topicId]);

  useEffect(() => {
    if (state.quizComplete && state.timeRemaining === 0) {
      handleQuizComplete();
    }
  }, [state.quizComplete, state.timeRemaining]);

  const handleSettingChange = (field: keyof QuizSettings, value: string) => {
    const numValue = parseInt(value);
    if (isNaN(numValue)) return;

    const limits = {
      timeLimit: { min: 1, max: 180 },
      questionCount: { min: 5, max: 50 },
      passingRate: { min: 1, max: 100 }
    };

    const { min, max } = limits[field];
    const clampedValue = Math.min(Math.max(numValue, min), max);

    setSettings(prev => ({
      ...prev,
      [field]: clampedValue
    }));
  };

  const fetchQuestions = async () => {
    if (!topicId) {
      setError('No topic ID provided');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const questions = await quizService.getQuestions(topicId, settings.questionCount);
      setQuestions(questions);
    } catch (error: any) {
      console.error('Error fetching questions:', error);
      setError(error.message || 'Failed to load questions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleStartQuiz = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowSettings(false);
    await fetchQuestions();
  };

  const handleQuizComplete = async () => {
    const score = state.score || calculateScore();
    const correctQuestions = Math.round((score / 100) * state.questions.length);
    
    try {
      await quizService.saveQuizResult({
        topicId,
        score,
        totalQuestions: state.questions.length,
        correctQuestions,
        timeTaken: settings.timeLimit * 60 - state.timeRemaining,
        passed: score >= settings.passingRate
      });
    } catch (error) {
      console.error('Error saving quiz result:', error);
    }
  };

  const handleRetry = () => {
    setShowSettings(false);
    quizState.setState(prev => ({
      ...prev,
      currentQuestionIndex: 0,
      selectedAnswers: {},
      timeRemaining: settings.timeLimit * 60,
      quizComplete: false,
      score: 0,
      flaggedQuestions: new Set()
    }));
  };

  const handleNewQuiz = () => {
    setShowSettings(true);
    quizState.setState(prev => ({
      ...prev,
      questions: [],
      currentQuestionIndex: 0,
      selectedAnswers: {},
      timeRemaining: settings.timeLimit * 60,
      quizComplete: false,
      score: 0,
      flaggedQuestions: new Set()
    }));
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  if (showSettings) {
    return (
      <QuizSettingsForm
        settings={settings}
        topicName={topicName}
        error={error}
        onSettingChange={handleSettingChange}
        onStart={handleStartQuiz}
      />
    );
  }

  if (loading) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            <span className="ml-3">Loading questions...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="bg-red-50 text-red-700 p-4 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
            <div>
              <p className="font-medium">{error}</p>
              <p className="mt-1 text-sm text-red-600">Please try again or select a different topic.</p>
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={() => navigate('/certifications')}
              className="px-4 py-2 text-blue-600 hover:text-blue-700"
            >
              Back to Certifications
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!state.questions.length) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="text-center text-gray-600">
            No questions available. Please try again later.
          </div>
        </div>
      </div>
    );
  }

  if (state.quizComplete) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <QuizResult
            questions={state.questions}
            selectedAnswers={state.selectedAnswers}
            score={state.score}
            passingScore={settings.passingRate}
            topicName={topicName}
            onRetry={handleRetry}
            onNewQuiz={handleNewQuiz}
          />
        </div>
      </div>
    );
  }

  const currentQuestion = state.questions[state.currentQuestionIndex];
  const selectedAnswerIds = state.selectedAnswers[currentQuestion.id] || [];
  const isQuestionFlagged = state.flaggedQuestions.has(state.currentQuestionIndex);

  return (
    <div className="max-w-3xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="mb-6">
          <AdSense />
        </div>

        <div className="flex justify-between items-center mb-6">
          <div className="text-lg font-semibold">
            Question {state.currentQuestionIndex + 1} of {state.questions.length}
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => toggleFlaggedQuestion(state.currentQuestionIndex)}
              className={`p-2 rounded-md ${
                isQuestionFlagged ? 'text-yellow-500' : 'text-gray-400'
              } hover:text-yellow-500`}
              title={isQuestionFlagged ? 'Unflag question' : 'Flag question for review'}
            >
              <Flag className="h-5 w-5" />
            </button>
            <button
              onClick={togglePause}
              className={`p-2 rounded-md ${
                isPaused ? 'text-green-500' : 'text-gray-400'
              } hover:text-green-500`}
              title={isPaused ? 'Resume test' : 'Pause test'}
            >
              {isPaused ? <Play className="h-5 w-5" /> : <Pause className="h-5 w-5" />}
            </button>
            <div className={`flex items-center text-gray-600 ${
              state.timeRemaining <= 300 ? 'text-red-600' : ''
            }`}>
              <Timer className="h-5 w-5 mr-2" />
              {formatTime(state.timeRemaining)}
            </div>
          </div>
        </div>

        {isPaused ? (
          <div className="text-center py-8">
            <h3 className="text-xl font-semibold mb-4">Test Paused</h3>
            <p className="text-gray-600 mb-4">Timer has been paused. Click the play button to resume.</p>
            <button
              onClick={togglePause}
              className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
            >
              <Play className="h-5 w-5 mr-2" />
              Resume Test
            </button>
          </div>
        ) : (
          <div className="space-y-6">
            <QuizQuestion
              question={currentQuestion}
              selectedAnswerIds={selectedAnswerIds}
              isQuestionFlagged={isQuestionFlagged}
              onAnswerSelect={(answerId) => selectAnswer(currentQuestion.id, answerId)}
              onFlagQuestion={() => toggleFlaggedQuestion(state.currentQuestionIndex)}
            />

            <div className="flex justify-between">
              <button
                onClick={() => quizState.setState(prev => ({ ...prev, currentQuestionIndex: prev.currentQuestionIndex - 1 }))}
                disabled={state.currentQuestionIndex === 0}
                className={`flex items-center px-4 py-2 rounded-md ${
                  state.currentQuestionIndex === 0
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                <ChevronLeft className="h-5 w-5 mr-1" />
                Previous
              </button>
              {state.currentQuestionIndex === state.questions.length - 1 ? (
                <button
                  onClick={handleQuizComplete}
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Complete Quiz
                  <CheckCircle className="h-5 w-5 ml-2" />
                </button>
              ) : (
                <button
                  onClick={() => quizState.setState(prev => ({ ...prev, currentQuestionIndex: prev.currentQuestionIndex + 1 }))}
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Next
                  <ChevronRight className="h-5 w-5 ml-1" />
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}