import { useState } from 'react';
import { Flashcard } from '../../../types/flashcard';
import { FlashcardService } from '../../../services/FlashcardService';

export function useFlashcardManager() {
  const [flashcards, setFlashcards] = useState<Flashcard[]>([]);
  const [loading, setLoading] = useState(false);
  const flashcardService = FlashcardService.getInstance();

  const fetchFlashcards = async (topicId?: string, categoryId?: string) => {
    if (!topicId && !categoryId) {
      setFlashcards([]);
      return;
    }

    setLoading(true);
    try {
      const data = await flashcardService.getFlashcardsForAdmin(topicId, categoryId);
      setFlashcards(data);
    } catch (error) {
      console.error('Error fetching flashcards:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateFlashcard = async (flashcardId: string, updates: Partial<Flashcard>) => {
    try {
      await flashcardService.updateFlashcard(flashcardId, updates);
      await fetchFlashcards(updates.topic_id, updates.category_id);
    } catch (error) {
      throw error;
    }
  };

  const deleteFlashcard = async (flashcardId: string, topicId: string, categoryId: string) => {
    try {
      await flashcardService.deleteFlashcard(flashcardId);
      await fetchFlashcards(topicId, categoryId);
    } catch (error) {
      throw error;
    }
  };

  const bulkDeleteFlashcards = async (flashcardIds: string[], topicId: string, categoryId: string) => {
    if (!flashcardIds.length) {
      throw new Error('No flashcards selected for deletion');
    }
    
    try {
      await flashcardService.bulkDeleteFlashcards(flashcardIds);
      await fetchFlashcards(topicId, categoryId);
    } catch (error: any) {
      console.error('Error bulk deleting flashcards:', error);
      throw new Error(error.message || 'Failed to delete flashcards');
    }
  };

  return {
    flashcards,
    loading,
    updateFlashcard,
    deleteFlashcard,
    bulkDeleteFlashcards,
    fetchFlashcards
  };
}