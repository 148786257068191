import React, { useState } from 'react';
import { AlertCircle, CheckCircle, Webhook } from 'lucide-react';
import { StripeWebhookTest } from '../../../services/StripeWebhookTest';

export function WebhookTest() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<{ success: boolean; message: string } | null>(null);
  const webhookTest = StripeWebhookTest.getInstance();

  const handleTest = async () => {
    setLoading(true);
    setResult(null);
    
    try {
      const testResult = await webhookTest.testWebhook();
      setResult(testResult);
    } catch (error: any) {
      setResult({
        success: false,
        message: `Test failed: ${error.message}`
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <Webhook className="h-5 w-5 text-blue-600" />
        <h2 className="text-xl font-semibold">Stripe Webhook Test</h2>
      </div>
      
      <p className="text-gray-600">
        Test the Stripe webhook endpoint to ensure it's properly configured and accessible.
      </p>
      
      <button
        onClick={handleTest}
        disabled={loading}
        className={`flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
          loading ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {loading ? (
          <>
            <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2" />
            Testing...
          </>
        ) : (
          'Test Webhook'
        )}
      </button>

      {result && (
        <div className={`p-4 rounded-md flex items-center ${
          result.success ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
        }`}>
          {result.success ? (
            <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          ) : (
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          )}
          <p>{result.message}</p>
        </div>
      )}
    </div>
  );
}