import { create } from 'zustand';
import { supabase } from '../lib/supabase';

interface AuthState {
  user: any | null;
  profile: any | null;
  isAdmin: boolean;
  setUser: (user: any) => void;
  setProfile: (profile: any) => void;
  signOut: () => Promise<void>;
  ensureProfile: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  profile: null,
  isAdmin: false,
  setUser: (user) => set({ user }),
  setProfile: (profile) => set({ profile, isAdmin: profile?.is_admin || false }),
  signOut: async () => {
    await supabase.auth.signOut();
    set({ user: null, profile: null, isAdmin: false });
  },
  ensureProfile: async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // First try to fetch existing profile
      const { data: profile, error: fetchError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (!fetchError && profile) {
        set({ profile, isAdmin: profile.is_admin || false });
        return;
      }

      // If profile doesn't exist, call RPC function to ensure it's created
      await supabase.rpc('ensure_profile_exists', { p_user_id: user.id });

      // Fetch the newly created profile
      const { data: newProfile, error: newProfileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (newProfileError) throw newProfileError;
      if (newProfile) {
        set({ profile: newProfile, isAdmin: newProfile.is_admin || false });
      }
    } catch (error) {
      console.error('Error ensuring profile:', error);
    }
  }
}));