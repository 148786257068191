import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, Github, Chrome } from 'lucide-react';
import { supabase } from '../../../lib/supabase';

// Define types at the top for better discoverability
type Provider = 'google' | 'github';

interface SSOConfig {
  provider: Provider;
  enabled: boolean;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
}

interface ProviderConfig {
  icon: typeof Chrome | typeof Github;
  title: string;
  iconColor: string;
}

// Configuration object for providers
const PROVIDER_CONFIGS: Record<Provider, ProviderConfig> = {
  google: {
    icon: Chrome,
    title: 'Google SSO',
    iconColor: 'text-red-500'
  },
  github: {
    icon: Github,
    title: 'GitHub SSO',
    iconColor: 'text-gray-900'
  }
};

// Default configuration factory
const createDefaultConfig = (provider: Provider): SSOConfig => ({
  provider,
  enabled: false,
  client_id: '',
  client_secret: '',
  redirect_uri: `${window.location.origin}/auth/callback`
});

// Form field configuration
const FORM_FIELDS = [
  {
    name: 'client_id' as const,
    label: 'Client ID',
    type: 'text',
    placeholder: 'Enter Client ID'
  },
  {
    name: 'client_secret' as const,
    label: 'Client Secret',
    type: 'password',
    placeholder: 'Enter Client Secret'
  },
  {
    name: 'redirect_uri' as const,
    label: 'Redirect URI',
    type: 'text',
    placeholder: 'Enter Redirect URI'
  }
];

// Reusable form field component
const FormField = ({
  label,
  type,
  value,
  onChange,
  placeholder
}: {
  label: string;
  type: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full px-3 py-2 border rounded-md"
      placeholder={placeholder}
    />
  </div>
);

// Reusable toggle component
const Toggle = ({
  enabled,
  onChange
}: {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
}) => (
  <div className="flex items-center">
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={enabled}
        onChange={(e) => onChange(e.target.checked)}
        className="sr-only peer"
      />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
      <span className="ml-3 text-sm font-medium text-gray-900">
        {enabled ? 'Enabled' : 'Disabled'}
      </span>
    </label>
  </div>
);

export function SSOManager() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [configs, setConfigs] = useState<Record<Provider, SSOConfig>>({
    google: createDefaultConfig('google'),
    github: createDefaultConfig('github')
  });

  useEffect(() => {
    fetchSSOConfig();
  }, []);

  const fetchSSOConfig = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('auth_config')
        .select('*');

      if (error) throw error;

      if (data) {
        const newConfigs = { ...configs };
        data.forEach(config => {
          if (config.provider in newConfigs) {
            newConfigs[config.provider as Provider] = {
              provider: config.provider as Provider,
              enabled: config.enabled || false,
              client_id: config.client_id || '',
              client_secret: config.client_secret || '',
              redirect_uri: config.redirect_uri || `${window.location.origin}/auth/callback`
            };
          }
        });
        setConfigs(newConfigs);
      }
    } catch (err: any) {
      console.error('Error fetching SSO config:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (provider: Provider) => {
    try {
      setLoading(true);
      setError(null);
      setSuccess(null);

      const config = configs[provider];
      const { error } = await supabase
        .from('auth_config')
        .upsert({
          provider: config.provider,
          enabled: config.enabled,
          client_id: config.client_id,
          client_secret: config.client_secret,
          redirect_uri: config.redirect_uri
        });

      if (error) throw error;

      setSuccess(`${provider.charAt(0).toUpperCase() + provider.slice(1)} SSO configuration saved successfully!`);
    } catch (err: any) {
      console.error('Error saving SSO config:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateConfig = (provider: Provider, updates: Partial<SSOConfig>) => {
    setConfigs(prev => ({
      ...prev,
      [provider]: { ...prev[provider], ...updates }
    }));
  };

  const renderProviderConfig = (provider: Provider) => {
    const config = configs[provider];
    const providerConfig = PROVIDER_CONFIGS[provider];
    const Icon = providerConfig.icon;

    return (
      <div className="border rounded-lg p-6">
        <div className="flex items-center mb-4">
          <Icon className={`h-6 w-6 ${providerConfig.iconColor} mr-2`} />
          <h3 className="text-xl font-medium">{providerConfig.title}</h3>
        </div>

        <div className="space-y-4">
          <Toggle
            enabled={config.enabled}
            onChange={(enabled) => updateConfig(provider, { enabled })}
          />

          {FORM_FIELDS.map(field => (
            <FormField
              key={field.name}
              label={field.label}
              type={field.type}
              value={config[field.name]}
              onChange={(value) => updateConfig(provider, { [field.name]: value })}
              placeholder={field.placeholder.replace('Client', provider.charAt(0).toUpperCase() + provider.slice(1))}
            />
          ))}

          <button
            onClick={() => handleSave(provider)}
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Save {providerConfig.title} Settings
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <h2 className="text-2xl font-semibold mb-6">SSO Configuration</h2>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md flex items-center">
            <CheckCircle className="h-5 w-5 mr-2" />
            {success}
          </div>
        )}

        <div className="space-y-8">
          {Object.keys(configs).map((provider) => (
            renderProviderConfig(provider as Provider)
          ))}
        </div>
      </div>
    </div>
  );
}