import React, { useState } from 'react';
import { AlertCircle, CheckCircle, Send } from 'lucide-react';
import { supabase } from '../../../lib/supabase';

export function PasswordResetTest() {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState<{ success: boolean; message: string; resetLink?: string } | null>(null);
  const [loading, setLoading] = useState(false);

  const handleTest = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setResult(null);

    try {
      const { data: { session }, error: authError } = await supabase.auth.getSession();
      if (authError) throw authError;
      if (!session) throw new Error('Not authenticated');

      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/test-password-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to test password reset');
      }

      const data = await response.json();
      setResult({
        success: true,
        message: data.message,
        resetLink: data.data?.resetLink
      });
      setEmail('');
    } catch (err: any) {
      console.error('Password reset test error:', err);
      setResult({
        success: false,
        message: err.message || 'Failed to test password reset'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold mb-4">Test Password Reset</h2>
      
      <form onSubmit={handleTest} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email Address</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required
            placeholder="Enter email to test"
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className={`bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 flex items-center justify-center w-full ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {loading ? (
            <>
              <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2" />
              Testing...
            </>
          ) : (
            <>
              <Send className="h-4 w-4 mr-2" />
              Test Reset
            </>
          )}
        </button>
      </form>

      {result && (
        <div className={`p-4 rounded-md flex items-center ${
          result.success ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
        }`}>
          {result.success ? (
            <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          ) : (
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          )}
          <div>
            <p>{result.message}</p>
            {result.resetLink && (
              <div className="mt-2">
                <p className="font-medium">Reset Link:</p>
                <pre className="mt-1 text-xs bg-white p-2 rounded overflow-x-auto">
                  {result.resetLink}
                </pre>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}