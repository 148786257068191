import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ContentPage {
  title: string;
  content: string;
  meta_title: string | null;
  meta_description: string | null;
  og_title: string | null;
  og_description: string | null;
  og_image: string | null;
  twitter_title: string | null;
  twitter_description: string | null;
  twitter_image: string | null;
}

export function ContentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState<ContentPage | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Get the slug from the pathname
  const slug = location.pathname.substring(1); // Remove leading slash

  useEffect(() => {
    const fetchPage = async () => {
      try {
        setLoading(true);
        setError(null);

        const { data, error } = await supabase
          .from('content_pages')
          .select('*')
          .eq('slug', slug)
          .single();

        if (error) throw error;
        if (!data) throw new Error('Page not found');

        setPage(data);

        // Update document head with SEO metadata
        document.title = data.meta_title || data.title;
        
        // Update meta description
        let metaDescription = document.querySelector('meta[name="description"]');
        if (!metaDescription) {
          metaDescription = document.createElement('meta');
          metaDescription.setAttribute('name', 'description');
          document.head.appendChild(metaDescription);
        }
        metaDescription.setAttribute('content', data.meta_description || '');

        // Update Open Graph meta tags
        const ogTags = {
          'og:title': data.og_title || data.title,
          'og:description': data.og_description || data.meta_description,
          'og:image': data.og_image || '',
          'og:url': window.location.href,
          'og:type': 'website'
        };

        Object.entries(ogTags).forEach(([property, content]) => {
          let metaTag = document.querySelector(`meta[property="${property}"]`);
          if (!metaTag) {
            metaTag = document.createElement('meta');
            metaTag.setAttribute('property', property);
            document.head.appendChild(metaTag);
          }
          metaTag.setAttribute('content', content || '');
        });

        // Update Twitter Card meta tags
        const twitterTags = {
          'twitter:card': 'summary_large_image',
          'twitter:title': data.twitter_title || data.og_title || data.title,
          'twitter:description': data.twitter_description || data.og_description || data.meta_description,
          'twitter:image': data.twitter_image || data.og_image || ''
        };

        Object.entries(twitterTags).forEach(([name, content]) => {
          let metaTag = document.querySelector(`meta[name="${name}"]`);
          if (!metaTag) {
            metaTag = document.createElement('meta');
            metaTag.setAttribute('name', name);
            document.head.appendChild(metaTag);
          }
          metaTag.setAttribute('content', content || '');
        });

      } catch (err: any) {
        console.error('Error fetching page:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchPage();
    }

    // Cleanup function to remove meta tags when component unmounts
    return () => {
      const metaTags = document.querySelectorAll('meta[name^="twitter:"], meta[property^="og:"], meta[name="description"]');
      metaTags.forEach(tag => tag.remove());
      document.title = 'ExamEngines.com - Your Path to Certification Success';
    };
  }, [slug]);

  if (loading) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            <span className="ml-3">Loading page...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error || !page) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="bg-red-50 text-red-700 p-4 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            <div>
              <p className="font-medium">Page not found</p>
              <button
                onClick={() => navigate('/')}
                className="mt-2 text-sm text-red-600 hover:text-red-800"
              >
                Return to Home
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-8">
        <article className="prose max-w-none">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{page.content}</ReactMarkdown>
        </article>
      </div>
    </div>
  );
}