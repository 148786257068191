import { supabase } from '../lib/supabase';

export class StripeWebhookTest {
  private static instance: StripeWebhookTest;
  private maxRetries = 3;
  private retryDelay = 1000; // 1 second
  private connectionTimeout = 15000; // 15 seconds

  private constructor() {}

  public static getInstance(): StripeWebhookTest {
    if (!StripeWebhookTest.instance) {
      StripeWebhookTest.instance = new StripeWebhookTest();
    }
    return StripeWebhookTest.instance;
  }

  private async checkConnection(): Promise<boolean> {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), this.connectionTimeout);

      const { data, error } = await supabase
        .from('categories')
        .select('count', { count: 'exact', head: true })
        .abortSignal(controller.signal);

      clearTimeout(timeoutId);

      if (error) {
        console.error('Connection check failed:', error);
        return false;
      }

      return true;
    } catch (error) {
      console.error('Connection check error:', error);
      return false;
    }
  }

  private async retryOperation<T>(operation: () => Promise<T>): Promise<T> {
    let lastError: any;
    let delay = this.retryDelay;

    // Check connection first
    const isConnected = await this.checkConnection();
    if (!isConnected) {
      throw new Error('Unable to connect to the server. Please check your internet connection and try again.');
    }

    for (let attempt = 1; attempt <= this.maxRetries; attempt++) {
      try {
        return await operation();
      } catch (error: any) {
        lastError = error;
        console.error(`Attempt ${attempt} failed:`, error);

        if (error.message?.includes('Failed to fetch') || error.code === 'ECONNABORTED') {
          throw new Error('Network connection lost. Please check your internet connection and try again.');
        }

        if (attempt < this.maxRetries) {
          // Add jitter to prevent thundering herd
          const jitter = Math.random() * 200;
          await new Promise(resolve => setTimeout(resolve, delay + jitter));
          delay *= 2; // Exponential backoff
          continue;
        }
      }
    }

    throw lastError || new Error('Operation failed after multiple attempts');
  }

  async testWebhook(): Promise<{ success: boolean; message: string }> {
    return this.retryOperation(async () => {
      try {
        // Get the access token
        const { data: { session }, error: authError } = await supabase.auth.getSession();
        if (authError) throw authError;
        if (!session) throw new Error('No active session');

        // Get the Supabase URL from environment
        const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
        if (!supabaseUrl) {
          throw new Error('Supabase URL not configured');
        }

        // Create a test event payload
        const testEvent = {
          type: 'test',
          data: {
            object: {
              id: 'test_event_' + Date.now(),
              created: Math.floor(Date.now() / 1000)
            }
          }
        };

        // Test the webhook endpoint with a timeout
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), this.connectionTimeout);

        const response = await fetch(`${supabaseUrl}/functions/v1/stripe-events-handler`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`,
            'stripe-signature': 'test_' + Date.now()
          },
          body: JSON.stringify(testEvent),
          signal: controller.signal
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return {
          success: true,
          message: data.message || 'Webhook endpoint is accessible'
        };
      } catch (error: any) {
        if (error.name === 'AbortError') {
          throw new Error('Request timed out. The webhook endpoint took too long to respond.');
        }
        throw error;
      }
    }).catch(error => ({
      success: false,
      message: `Error testing webhook: ${error.message}`
    }));
  }
}