import React, { useState, useEffect } from 'react';
import { Edit2, Save, AlertCircle } from 'lucide-react';
import { supabase } from '../../../lib/supabase';

interface ContentPage {
  id: string;
  title: string;
  slug: string;
  content: string;
  category: string;
  created_at: string;
  updated_at: string;
}

export function FooterManager() {
  const [pages, setPages] = useState<ContentPage[]>([]);
  const [editingPage, setEditingPage] = useState<ContentPage | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('content_pages')
        .select('*')
        .order('category', { ascending: true })
        .order('title', { ascending: true });

      if (error) throw error;
      setPages(data || []);
    } catch (err: any) {
      console.error('Error fetching pages:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!editingPage) return;

    try {
      const { error } = await supabase
        .from('content_pages')
        .update({
          title: editingPage.title,
          content: editingPage.content,
          updated_at: new Date().toISOString()
        })
        .eq('id', editingPage.id);

      if (error) throw error;

      setSuccess('Page updated successfully');
      setEditingPage(null);
      fetchPages();
    } catch (err: any) {
      console.error('Error updating page:', err);
      setError(err.message);
    }
  };

  const getCategoryTitle = (category: string) => {
    switch (category) {
      case 'company':
        return 'Company';
      case 'legal':
        return 'Legal';
      case 'support':
        return 'Support';
      default:
        return category;
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          <span className="ml-3">Loading pages...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <h2 className="text-2xl font-semibold mb-6">Footer Content Management</h2>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md">
            {success}
          </div>
        )}

        <div className="space-y-8">
          {['company', 'legal', 'support'].map(category => {
            const categoryPages = pages.filter(page => page.category === category);
            
            return (
              <div key={category} className="border rounded-lg p-4">
                <h3 className="text-xl font-semibold mb-4">{getCategoryTitle(category)}</h3>
                <div className="space-y-4">
                  {categoryPages.map(page => (
                    <div key={page.id} className="border-b pb-4 last:border-b-0 last:pb-0">
                      {editingPage?.id === page.id ? (
                        <div className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Title
                            </label>
                            <input
                              type="text"
                              value={editingPage.title}
                              onChange={(e) => setEditingPage({
                                ...editingPage,
                                title: e.target.value
                              })}
                              className="w-full px-3 py-2 border rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Content (Markdown)
                            </label>
                            <textarea
                              value={editingPage.content}
                              onChange={(e) => setEditingPage({
                                ...editingPage,
                                content: e.target.value
                              })}
                              rows={10}
                              className="w-full px-3 py-2 border rounded-md font-mono text-sm"
                            />
                          </div>
                          <div className="flex justify-end gap-2">
                            <button
                              onClick={() => setEditingPage(null)}
                              className="px-4 py-2 text-gray-600 hover:text-gray-800"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleSave}
                              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                            >
                              <Save className="h-4 w-4 mr-2" />
                              Save Changes
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <h4 className="text-lg font-medium">{page.title}</h4>
                            <button
                              onClick={() => setEditingPage(page)}
                              className="text-blue-600 hover:text-blue-800"
                            >
                              <Edit2 className="h-4 w-4" />
                            </button>
                          </div>
                          <p className="text-sm text-gray-500">
                            Last updated: {new Date(page.updated_at).toLocaleDateString()}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}