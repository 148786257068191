import React from 'react';
import { Link } from 'react-router-dom';
import { 
  BookOpen, 
  Award, 
  Brain, 
  Shield, 
  CheckCircle, 
  Target, 
  Users, 
  Zap,
  Cloud,
  Network,
  Code,
  Database,
  Server,
  Lock,
  Globe,
  Cpu
} from 'lucide-react';
import { Footer } from '../components/Footer';

export function Home() {
  return (
    <>
      <div className="space-y-16">
        {/* Hero Section */}
        <div className="text-center space-y-6">
          <h1 className="text-5xl font-bold text-gray-900">
            Master Your Tech Certifications
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Accelerate your career with our comprehensive certification preparation platform. 
            Practice exams, flashcards, and expert-curated content to help you succeed.
          </p>
          <div className="flex justify-center gap-4">
            <Link
              to="/demo/quiz"
              className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium"
            >
              Try Practice Quiz
            </Link>
            <Link
              to="/login"
              className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 font-medium"
            >
              Get Started
            </Link>
            <Link
              to="/demo/flashcards"
              className="px-6 py-3 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 font-medium"
            >
              Try Flashcards
            </Link>
          </div>
        </div>

        {/* Certification Paths */}
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-8">
            <h2 className="text-3xl font-bold text-gray-900 mb-8">Certification Paths</h2>
            
            <div className="grid gap-8">
              {/* Cloud Computing */}
              <div className="bg-white rounded-lg border shadow-sm">
                <div className="p-6">
                  <div className="flex items-center space-x-4 mb-6">
                    <Cloud className="h-8 w-8 text-blue-600" />
                    <h3 className="text-2xl font-semibold">Cloud Computing</h3>
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">AWS Certifications</h4>
                      <p className="text-gray-600 text-sm mb-4">Amazon Web Services certification paths for cloud professionals.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">Azure Certifications</h4>
                      <p className="text-gray-600 text-sm mb-4">Microsoft Azure certifications for cloud and hybrid solutions.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">Google Cloud</h4>
                      <p className="text-gray-600 text-sm mb-4">Google Cloud Platform certifications for cloud engineers.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Security */}
              <div className="bg-white rounded-lg border shadow-sm">
                <div className="p-6">
                  <div className="flex items-center space-x-4 mb-6">
                    <Shield className="h-8 w-8 text-red-600" />
                    <h3 className="text-2xl font-semibold">Security</h3>
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">CompTIA Security+</h4>
                      <p className="text-gray-600 text-sm mb-4">Foundation security certification for IT professionals.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">CISSP</h4>
                      <p className="text-gray-600 text-sm mb-4">Advanced security certification for cybersecurity leaders.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">CEH</h4>
                      <p className="text-gray-600 text-sm mb-4">Ethical Hacking and penetration testing certification.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Networking */}
              <div className="bg-white rounded-lg border shadow-sm">
                <div className="p-6">
                  <div className="flex items-center space-x-4 mb-6">
                    <Network className="h-8 w-8 text-green-600" />
                    <h3 className="text-2xl font-semibold">Networking</h3>
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">CCNA</h4>
                      <p className="text-gray-600 text-sm mb-4">Cisco Certified Network Associate certification.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">Network+</h4>
                      <p className="text-gray-600 text-sm mb-4">CompTIA Network+ certification for networking professionals.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">CCNP</h4>
                      <p className="text-gray-600 text-sm mb-4">Advanced Cisco networking certification.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* DevOps */}
              <div className="bg-white rounded-lg border shadow-sm">
                <div className="p-6">
                  <div className="flex items-center space-x-4 mb-6">
                    <Code className="h-8 w-8 text-purple-600" />
                    <h3 className="text-2xl font-semibold">DevOps</h3>
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">Docker Certified</h4>
                      <p className="text-gray-600 text-sm mb-4">Container technology and orchestration certification.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">Kubernetes</h4>
                      <p className="text-gray-600 text-sm mb-4">Container orchestration and cloud-native certification.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-2">Jenkins</h4>
                      <p className="text-gray-600 text-sm mb-4">Continuous Integration and Deployment certification.</p>
                      <Link to="/login" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                        Start Learning →
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Key Features */}
        <div className="grid md:grid-cols-3 gap-8">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <div className="flex items-center justify-center w-14 h-14 bg-blue-100 rounded-lg mb-6">
              <BookOpen className="h-8 w-8 text-blue-600" />
            </div>
            <h2 className="text-xl font-semibold mb-4">Comprehensive Study Materials</h2>
            <p className="text-gray-600 mb-4">
              Access detailed study guides, practice questions, and exam tips curated by industry experts.
            </p>
            <Link
              to="/demo/quiz"
              className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
            >
              Try Practice Quiz <Zap className="h-4 w-4 ml-2" />
            </Link>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-md">
            <div className="flex items-center justify-center w-14 h-14 bg-green-100 rounded-lg mb-6">
              <Target className="h-8 w-8 text-green-600" />
            </div>
            <h2 className="text-xl font-semibold mb-4">Practice Tests</h2>
            <p className="text-gray-600 mb-4">
              Simulate real exam conditions with our extensive collection of practice questions and timed tests.
            </p>
            <Link
              to="/demo/quiz"
              className="text-green-600 hover:text-green-700 font-medium inline-flex items-center"
            >
              Start Practice <CheckCircle className="h-4 w-4 ml-2" />
            </Link>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-md">
            <div className="flex items-center justify-center w-14 h-14 bg-purple-100 rounded-lg mb-6">
              <Brain className="h-8 w-8 text-purple-600" />
            </div>
            <h2 className="text-xl font-semibold mb-4">Interactive Flashcards</h2>
            <p className="text-gray-600 mb-4">
              Master key concepts with our adaptive flashcard system designed for efficient learning.
            </p>
            <Link
              to="/demo/flashcards"
              className="text-purple-600 hover:text-purple-700 font-medium inline-flex items-center"
            >
              View Flashcards <Brain className="h-4 w-4 ml-2" />
            </Link>
          </div>
        </div>

        {/* Why Choose Us */}
        <div className="bg-gray-50 rounded-lg p-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Why Choose ExamEngines.com?</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              We provide the tools and resources you need to succeed in your certification journey.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="flex items-center justify-center w-14 h-14 bg-blue-100 rounded-full mx-auto mb-4">
                <Users className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">AI-Led Content</h3>
              <p className="text-gray-600">
                Study materials created by Multi-Mode AI.
              </p>
            </div>

            <div className="text-center">
              <div className="flex items-center justify-center w-14 h-14 bg-green-100 rounded-full mx-auto mb-4">
                <Target className="h-6 w-6 text-green-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Exam-Focused</h3>
              <p className="text-gray-600">
                Practice questions aligned with the latest exam objectives and formats.
              </p>
            </div>

            <div className="text-center">
              <div className="flex items-center justify-center w-14 h-14 bg-purple-100 rounded-full mx-auto mb-4">
                <Zap className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Always Updated</h3>
              <p className="text-gray-600">
                Regular content updates to reflect the latest certification requirements.
              </p>
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="text-center bg-blue-600 text-white rounded-lg p-12">
          <h2 className="text-3xl font-bold mb-4">Ready to Get Certified?</h2>
          <p className="text-xl mb-8 opacity-90">
            Start your certification journey today with our comprehensive study materials.
          </p>
          <Link
            to="/login"
            className="inline-block bg-white text-blue-600 px-8 py-4 rounded-lg font-medium hover:bg-blue-50"
          >
            Get Started
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}