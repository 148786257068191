import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle, XCircle, BookOpen, Brain, ArrowRight, Target } from 'lucide-react';
import { Question } from '../../types/quiz';
import { FeedbackForm } from './FeedbackForm';

interface QuizResultProps {
  questions: Question[];
  selectedAnswers: Record<string, string[]>;
  score: number;
  passingScore: number;
  topicName?: string;
  onRetry: () => void;
  onNewQuiz: () => void;
}

export function QuizResult({ 
  questions, 
  selectedAnswers, 
  score, 
  passingScore,
  topicName = '',
  onRetry, 
  onNewQuiz 
}: QuizResultProps) {
  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <div className="text-center">
        <div className="text-4xl font-bold mb-2">{score}%</div>
        <div className="text-gray-600">
          {score >= passingScore ? (
            <div className="flex items-center justify-center text-green-600">
              <CheckCircle className="h-6 w-6 mr-2" />
              Passed
            </div>
          ) : (
            <div className="flex items-center justify-center text-red-600">
              <XCircle className="h-6 w-6 mr-2" />
              Failed
            </div>
          )}
        </div>
      </div>

      <div className="space-y-4">
        {questions.map((question, index) => {
          const selectedAnswerIds = selectedAnswers[question.id] || [];
          const correctAnswerIds = question.answers
            .filter(answer => answer.is_correct)
            .map(answer => answer.id);
          
          const isCorrect = 
            selectedAnswerIds.length === correctAnswerIds.length &&
            selectedAnswerIds.every(id => correctAnswerIds.includes(id));

          return (
            <div key={question.id} className="border rounded-lg p-4">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="font-medium mb-2">
                    Question {index + 1}: {question.question_text}
                  </div>
                  <div className="space-y-2">
                    {question.answers.map((answer, index) => {
                      const isSelected = selectedAnswerIds.includes(answer.id);
                      const isCorrectAnswer = answer.is_correct;

                      return (
                        <div
                          key={answer.id}
                          className={`flex items-center p-2 rounded ${
                            isSelected
                              ? isCorrectAnswer
                                ? 'bg-green-50 text-green-700'
                                : 'bg-red-50 text-red-700'
                              : isCorrectAnswer
                              ? 'bg-green-50 text-green-700'
                              : ''
                          }`}
                        >
                          {answer.answer_text}
                          {isSelected && !isCorrectAnswer && (
                            <XCircle className="h-4 w-4 ml-2" />
                          )}
                          {isCorrectAnswer && (
                            <CheckCircle className="h-4 w-4 ml-2" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {question.explanation && (
                    <div className="mt-2 text-sm text-gray-600">
                      <strong>Explanation:</strong> {question.explanation}
                    </div>
                  )}
                </div>
                <div className="ml-4">
                  {isCorrect ? (
                    <CheckCircle className="h-6 w-6 text-green-600" />
                  ) : (
                    <XCircle className="h-6 w-6 text-red-600" />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col sm:flex-row justify-center gap-4 pt-6 border-t">
        <button
          onClick={onRetry}
          className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 w-full sm:w-auto"
        >
          <Target className="h-5 w-5 mr-2" />
          Try Again
        </button>
        <button
          onClick={onNewQuiz}
          className="flex items-center justify-center px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 w-full sm:w-auto"
        >
          <BookOpen className="h-5 w-5 mr-2" />
          New Quiz
        </button>
        <button
          onClick={() => navigate(`/flashcards/${questions[0].topic_id}`)}
          className="flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 w-full sm:w-auto"
        >
          <Brain className="h-5 w-5 mr-2" />
          Study Flashcards
        </button>
        <button
          onClick={() => navigate('/certifications')}
          className="flex items-center justify-center px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 w-full sm:w-auto"
        >
          <ArrowRight className="h-5 w-5 mr-2" />
          Certifications
        </button>
      </div>

      <div className="mt-8">
        <FeedbackForm quizScore={score} topicName={topicName} />
      </div>
    </div>
  );
}