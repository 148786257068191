import { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabase';

interface Topic {
  id: string;
  category_id: string;
  name: string;
  description: string;
}

export function useTopics() {
  const [topics, setTopics] = useState<Topic[]>([]);

  const fetchTopics = async () => {
    try {
      const { data, error } = await supabase
        .from('topics')
        .select('*')
        .order('name');
      
      if (error) throw error;
      setTopics(data || []);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const addTopic = async (topic: Omit<Topic, 'id'>) => {
    try {
      // Check if topic name already exists in the category
      const { data: existingTopics, error: checkError } = await supabase
        .from('topics')
        .select('id')
        .eq('category_id', topic.category_id)
        .ilike('name', topic.name);

      if (checkError) throw checkError;

      if (existingTopics && existingTopics.length > 0) {
        throw new Error(`A topic with the name "${topic.name}" already exists in this category`);
      }

      const { error } = await supabase
        .from('topics')
        .insert([topic]);

      if (error) throw error;
      await fetchTopics();
    } catch (error: any) {
      console.error('Error adding topic:', error);
      throw new Error(error.message || 'Failed to add topic');
    }
  };

  const updateTopic = async (topic: Topic) => {
    try {
      // Check if the updated name would create a duplicate
      const { data: existingTopics, error: checkError } = await supabase
        .from('topics')
        .select('id')
        .eq('category_id', topic.category_id)
        .ilike('name', topic.name)
        .neq('id', topic.id);

      if (checkError) throw checkError;

      if (existingTopics && existingTopics.length > 0) {
        throw new Error(`A topic with the name "${topic.name}" already exists in this category`);
      }

      const { error } = await supabase
        .from('topics')
        .update({
          name: topic.name,
          description: topic.description,
          category_id: topic.category_id
        })
        .eq('id', topic.id);

      if (error) throw error;
      await fetchTopics();
    } catch (error: any) {
      console.error('Error updating topic:', error);
      throw new Error(error.message || 'Failed to update topic');
    }
  };

  const deleteTopic = async (id: string) => {
    try {
      const { error } = await supabase
        .from('topics')
        .delete()
        .eq('id', id);

      if (error) throw error;
      await fetchTopics();
    } catch (error: any) {
      console.error('Error deleting topic:', error);
      throw new Error(error.message || 'Failed to delete topic');
    }
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  return {
    topics,
    addTopic,
    updateTopic,
    deleteTopic,
    refreshTopics: fetchTopics
  };
}