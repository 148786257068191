import { supabase } from '../lib/supabase';

export interface TopicFlashcardCount {
  topic_id: string;
  count: number;
}

export class FlashcardStatisticsService {
  private static instance: FlashcardStatisticsService;

  private constructor() {}

  public static getInstance(): FlashcardStatisticsService {
    if (!FlashcardStatisticsService.instance) {
      FlashcardStatisticsService.instance = new FlashcardStatisticsService();
    }
    return FlashcardStatisticsService.instance;
  }

  async getFlashcardCountsByTopic(): Promise<TopicFlashcardCount[]> {
    try {
      const { data, error } = await supabase.rpc('get_topic_flashcard_counts');

      if (error) {
        console.error('Database error:', error);
        return []; // Return empty array instead of throwing
      }

      return data || [];
    } catch (error) {
      console.error('Error fetching flashcard counts:', error);
      return []; // Return empty array instead of throwing
    }
  }

  async getFlashcardCountForTopic(topicId: string): Promise<number> {
    try {
      const { count, error } = await supabase
        .from('flashcards')
        .select('*', { count: 'exact', head: true })
        .eq('topic_id', topicId);

      if (error) {
        console.error('Database error:', error);
        return 0;
      }

      return count || 0;
    } catch (error) {
      console.error('Error fetching flashcard count for topic:', error);
      return 0;
    }
  }

  async getTotalFlashcardCount(): Promise<number> {
    try {
      const { count, error } = await supabase
        .from('flashcards')
        .select('*', { count: 'exact', head: true });

      if (error) {
        console.error('Database error:', error);
        return 0;
      }

      return count || 0;
    } catch (error) {
      console.error('Error fetching total flashcard count:', error);
      return 0;
    }
  }
}