import React, { useState, useEffect } from 'react';
import { Send, CheckCircle, AlertCircle } from 'lucide-react';
import emailjs from '@emailjs/browser';

interface FeedbackFormProps {
  quizScore: number;
  topicName: string;
}

export function FeedbackForm({ quizScore, topicName }: FeedbackFormProps) {
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Initialize EmailJS with your public key
    emailjs.init("fDeE6eZ4QiPSWSENQ");
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await emailjs.send(
        "service_bfkpmws", // Email service ID from EmailJS
        "template_49pzyib", // Email template ID from EmailJS
        {
          email: "feedback@examengines.com",
          to_name: "ExamEngines Feedback Team",
          from_name: email ? email.split('@')[0] : 'Anonymous User',
          from_email: email || 'support@examengines.com',
          reply_to: email || 'noreply@examengines.com',
          message: feedback,
          quiz_score: quizScore,
          topic_name: topicName,
          timestamp: new Date().toLocaleString()
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to send feedback');
      }

      setSuccess(true);
      setFeedback('');
      setEmail('');
    } catch (err: any) {
      console.error('Error sending feedback:', err);
      setError(err.text || err.message || 'Failed to send feedback. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (success) {
    return (
      <div className="bg-green-50 text-green-700 p-4 rounded-md flex items-center">
        <CheckCircle className="h-5 w-5 mr-2" />
        <p>Thank you for your feedback! We appreciate your input.</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h3 className="text-lg font-semibold mb-4">Share Your Feedback</h3>
      <p className="text-gray-600 mb-4">
        Help us improve our practice tests by sharing your experience.
      </p>

      <form onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertCircle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  {error}
                </h3>
              </div>
            </div>
          </div>
        )}

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
            Your Email (optional)
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            placeholder="Enter your email for follow-up"
          />
        </div>

        <div>
          <label htmlFor="feedback" className="block text-sm font-medium text-gray-700 mb-1">
            Your Feedback
          </label>
          <textarea
            id="feedback"
            name="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            rows={4}
            required
            placeholder="Share your thoughts about the questions, difficulty level, or any suggestions for improvement..."
          />
        </div>

        <button
          type="submit"
          disabled={isSubmitting || !feedback.trim()}
          className={`flex items-center justify-center w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
            isSubmitting || !feedback.trim() ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <Send className="h-4 w-4 mr-2" />
          {isSubmitting ? 'Sending...' : 'Send Feedback'}
        </button>
      </form>
    </div>
  );
}