import React, { useState, useRef } from 'react';
import { Upload, AlertCircle, CheckCircle } from 'lucide-react';
import { useCategories } from '../hooks/useCategories';
import { useTopics } from '../hooks/useTopics';
import { FlashcardService } from '../../../services/FlashcardService';

export function FlashcardFileUploader() {
  const { categories } = useCategories();
  const { topics } = useTopics();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [uploadStatus, setUploadStatus] = useState<{ success: boolean; message: string } | null>(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const flashcardService = FlashcardService.getInstance();

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!selectedTopicId || !selectedCategoryId) {
      setUploadStatus({
        success: false,
        message: 'Please select both a category and topic before uploading.'
      });
      return;
    }

    setLoading(true);
    setUploadStatus(null);

    try {
      const text = await file.text();
      await flashcardService.createFlashcards(selectedTopicId, selectedCategoryId, text);
      
      setUploadStatus({
        success: true,
        message: 'Flashcards uploaded successfully!'
      });

      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error: any) {
      setUploadStatus({
        success: false,
        message: error.message || 'Error uploading flashcards. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6">
          <div className="flex items-center mb-4">
            <Upload className="h-6 w-6 text-blue-600 mr-2" />
            <h2 className="text-2xl font-semibold">Upload Flashcards File</h2>
          </div>
          
          <div className="space-y-6">
            {/* Topic Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Select Category and Topic</label>
              <div className="grid grid-cols-2 gap-4">
                <select
                  value={selectedCategoryId}
                  onChange={(e) => {
                    setSelectedCategoryId(e.target.value);
                    setSelectedTopicId('');
                  }}
                  className="px-3 py-2 border rounded-md"
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <select
                  value={selectedTopicId}
                  onChange={(e) => setSelectedTopicId(e.target.value)}
                  className="px-3 py-2 border rounded-md"
                  disabled={!selectedCategoryId}
                >
                  <option value="">Select Topic</option>
                  {topics
                    .filter(topic => topic.category_id === selectedCategoryId)
                    .map((topic) => (
                      <option key={topic.id} value={topic.id}>
                        {topic.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* Format Example */}
            <div className="bg-gray-50 p-6 rounded-md space-y-4">
              <h4 className="font-medium text-gray-700">Format Example:</h4>
              <pre className="text-sm text-gray-600 whitespace-pre-wrap bg-white p-4 rounded-md border border-gray-200">
{`Front: What is Infrastructure as Code (IaC)?
Back: A practice where infrastructure is provisioned and managed using code and software development techniques

Front: What are the benefits of using Terraform?
Back: - Declarative configuration
- Infrastructure version control
- Reusable modules
- Multi-cloud support
- Consistent infrastructure

Front: What is a VPC?
Back: A Virtual Private Cloud (VPC) is an isolated section of the cloud where you can launch resources in a defined virtual network`}
              </pre>
            </div>

            {/* File Upload */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Upload Flashcards File
                </label>
                <input
                  type="file"
                  accept=".txt"
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                  className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-md file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100"
                  disabled={loading}
                />
              </div>

              {uploadStatus && (
                <div className={`p-4 rounded-md flex items-center ${
                  uploadStatus.success ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
                }`}>
                  {uploadStatus.success ? (
                    <CheckCircle className="h-5 w-5 mr-2" />
                  ) : (
                    <AlertCircle className="h-5 w-5 mr-2" />
                  )}
                  {uploadStatus.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}