import React, { useState, useEffect } from 'react';
import { CreditCard, DollarSign, Users, AlertCircle, CheckCircle } from 'lucide-react';
import { supabase } from '../../../lib/supabase';
import type { SubscriptionTier } from '../../../types/subscription';

export function SubscriptionManager() {
  const [tiers, setTiers] = useState<SubscriptionTier[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [editingTier, setEditingTier] = useState<SubscriptionTier | null>(null);

  useEffect(() => {
    fetchTiers();
  }, []);

  const fetchTiers = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('subscription_tiers')
        .select('*')
        .order('price_amount');

      if (error) throw error;
      setTiers(data || []);
    } catch (err: any) {
      console.error('Error fetching tiers:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!editingTier) return;

    try {
      setError(null);
      const { error } = await supabase
        .rpc('admin_update_subscription_tier', {
          p_tier_id: editingTier.id,
          p_name: editingTier.name,
          p_description: editingTier.description,
          p_price_id: editingTier.price_id,
          p_price_amount: editingTier.price_amount,
          p_currency: editingTier.currency,
          p_interval: editingTier.interval,
          p_features: editingTier.features,
          p_active: editingTier.active
        });

      if (error) throw error;

      setSuccess('Subscription tier updated successfully');
      setEditingTier(null);
      fetchTiers();
    } catch (err: any) {
      console.error('Error updating tier:', err);
      setError(err.message);
    }
  };

  const getIntervalDisplay = (interval: string) => {
    switch (interval) {
      case 'month':
        return 'Monthly';
      case '3months':
        return '3 Months';
      case 'year':
        return 'Yearly';
      default:
        return interval;
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          <span className="ml-3">Loading subscription tiers...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="flex items-center mb-6">
          <CreditCard className="h-6 w-6 text-blue-600 mr-2" />
          <h2 className="text-2xl font-semibold">Subscription Management</h2>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md flex items-center">
            <CheckCircle className="h-5 w-5 mr-2" />
            {success}
          </div>
        )}

        <div className="space-y-6">
          {tiers.map((tier) => (
            <div key={tier.id} className="border rounded-lg p-6">
              {editingTier?.id === tier.id ? (
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Name
                    </label>
                    <input
                      type="text"
                      value={editingTier.name}
                      onChange={(e) => setEditingTier({
                        ...editingTier,
                        name: e.target.value
                      })}
                      className="w-full px-3 py-2 border rounded-md"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Description
                    </label>
                    <textarea
                      value={editingTier.description || ''}
                      onChange={(e) => setEditingTier({
                        ...editingTier,
                        description: e.target.value
                      })}
                      className="w-full px-3 py-2 border rounded-md"
                      rows={3}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Price ID (Stripe)
                      </label>
                      <input
                        type="text"
                        value={editingTier.price_id}
                        onChange={(e) => setEditingTier({
                          ...editingTier,
                          price_id: e.target.value
                        })}
                        className="w-full px-3 py-2 border rounded-md"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Price Amount (cents)
                      </label>
                      <input
                        type="number"
                        value={editingTier.price_amount}
                        onChange={(e) => setEditingTier({
                          ...editingTier,
                          price_amount: parseInt(e.target.value)
                        })}
                        className="w-full px-3 py-2 border rounded-md"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Currency
                      </label>
                      <input
                        type="text"
                        value={editingTier.currency}
                        onChange={(e) => setEditingTier({
                          ...editingTier,
                          currency: e.target.value.toLowerCase()
                        })}
                        className="w-full px-3 py-2 border rounded-md"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Interval
                      </label>
                      <select
                        value={editingTier.interval}
                        onChange={(e) => setEditingTier({
                          ...editingTier,
                          interval: e.target.value
                        })}
                        className="w-full px-3 py-2 border rounded-md"
                      >
                        <option value="month">Monthly</option>
                        <option value="3months">3 Months</option>
                        <option value="year">Yearly</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Features (one per line)
                    </label>
                    <textarea
                      value={editingTier.features.join('\n')}
                      onChange={(e) => setEditingTier({
                        ...editingTier,
                        features: e.target.value.split('\n').filter(f => f.trim())
                      })}
                      className="w-full px-3 py-2 border rounded-md font-mono text-sm"
                      rows={4}
                    />
                  </div>

                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={editingTier.active}
                      onChange={(e) => setEditingTier({
                        ...editingTier,
                        active: e.target.checked
                      })}
                      className="h-4 w-4 text-blue-600 rounded border-gray-300"
                    />
                    <label className="ml-2 text-sm text-gray-700">
                      Active
                    </label>
                  </div>

                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => setEditingTier(null)}
                      className="px-4 py-2 text-gray-600 hover:text-gray-800"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-medium">{tier.name}</h3>
                      <p className="text-gray-600">{tier.description}</p>
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <DollarSign className="h-4 w-4 mr-1" />
                        {(tier.price_amount / 100).toFixed(2)} {tier.currency.toUpperCase()} / {getIntervalDisplay(tier.interval)}
                      </div>
                      <div className="mt-4 space-y-1">
                        {tier.features.map((feature, index) => (
                          <div key={index} className="flex items-center text-sm">
                            <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                            {feature}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div className={`px-2 py-1 rounded text-sm ${
                        tier.active ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                      }`}>
                        {tier.active ? 'Active' : 'Inactive'}
                      </div>
                      <button
                        onClick={() => setEditingTier(tier)}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-8 text-center text-sm text-gray-500">
          <p>
            Subscriptions are processed securely through Stripe. 
            You can cancel at any time.
          </p>
        </div>
      </div>
    </div>
  );
}