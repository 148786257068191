import React from 'react';
import { Edit2, Trash2 } from 'lucide-react';
import { QuestionViewProps } from '../../../types/question';

export function QuestionView({
  question,
  index,
  isSelected,
  onToggleSelect,
  onEdit,
  onDelete,
  topics
}: QuestionViewProps) {
  const formatQuestionText = (text: string) => {
    return text.split('\n').map((line, i) => {
      const codeRegex = /`([^`]+)`/g;
      const parts = line.split(codeRegex);
      
      return (
        <React.Fragment key={i}>
          {parts.map((part, j) => {
            if (j % 2 === 1) {
              return (
                <code key={j} className="px-1.5 py-0.5 bg-gray-100 rounded font-mono text-sm">
                  {part}
                </code>
              );
            }
            return part;
          })}
          {i < text.split('\n').length - 1 && <br />}
        </React.Fragment>
      );
    });
  };

  return (
    <div>
      <div className="flex justify-between items-start mb-2">
        <div className="flex-1">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onToggleSelect(question.id)}
              className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
            />
            <div className="text-sm text-gray-500">
              <span className="font-medium">Question #{index + 1}</span> • 
              Topic: {topics.find(t => t.id === question.topic_id)?.name}
            </div>
          </div>
          <div className="mt-2">
            {formatQuestionText(question.question_text)}
          </div>
          {question.image_url && (
            <div className="mt-2">
              <img
                src={question.image_url}
                alt="Question"
                className="max-w-full h-auto rounded-md"
              />
            </div>
          )}
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => onEdit(question)}
            className="text-blue-600 hover:text-blue-800"
          >
            <Edit2 className="h-4 w-4" />
          </button>
          <button
            onClick={() => onDelete(question.id)}
            className="text-red-600 hover:text-red-800"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      </div>
      <p className="text-gray-600 text-sm mb-2">
        {question.explanation}
      </p>
      <div className="space-y-1">
        {question.answers.map((answer, index) => (
          <div
            key={index}
            className={`text-sm ${
              answer.is_correct ? 'text-green-600' : 'text-gray-600'
            }`}
          >
            {String.fromCharCode(65 + index)}. {answer.answer_text}
            {answer.is_correct && ' ✓'}
          </div>
        ))}
      </div>
    </div>
  );
}