import { supabase } from '../lib/supabase';
import { Question, QuizSettings, QuizResult } from '../types/quiz';

export class QuizService {
  private static instance: QuizService;

  private constructor() {}

  public static getInstance(): QuizService {
    if (!QuizService.instance) {
      QuizService.instance = new QuizService();
    }
    return QuizService.instance;
  }

  async getTopicName(topicId: string): Promise<string> {
    const { data, error } = await supabase
      .from('topics')
      .select('name')
      .eq('id', topicId)
      .single();

    if (error) throw error;
    return data?.name || '';
  }

  async getQuestions(topicId: string, limit: number): Promise<Question[]> {
    // First, verify the topic exists
    const { data: topicCheck, error: topicError } = await supabase
      .from('topics')
      .select('id, name')
      .eq('id', topicId)
      .single();

    if (topicError || !topicCheck) {
      throw new Error('Topic not found');
    }

    // Then fetch questions for this topic
    const { data: questionsData, error: questionsError } = await supabase
      .from('questions')
      .select(`
        id,
        question_text,
        explanation,
        topic_id,
        answers (
          id,
          answer_text,
          is_correct
        )
      `)
      .eq('topic_id', topicId)
      .order('created_at')
      .limit(limit);

    if (questionsError) throw questionsError;

    if (!questionsData || questionsData.length === 0) {
      throw new Error(`No questions available for ${topicCheck.name}`);
    }

    // Validate questions have answers
    const validQuestions = questionsData.filter(q => q.answers && q.answers.length > 0);
    
    if (validQuestions.length === 0) {
      throw new Error('No valid questions found for this topic');
    }

    // Randomize question order
    return [...validQuestions].sort(() => Math.random() - 0.5);
  }

  async saveQuizResult(result: QuizResult): Promise<void> {
    const { error } = await supabase
      .from('user_progress')
      .insert([{
        user_id: (await supabase.auth.getUser()).data.user?.id,
        topic_id: result.topicId,
        score: result.score,
        questions_total: result.totalQuestions,
        questions_correct: result.correctQuestions,
        time_taken: result.timeTaken,
        completed_at: new Date().toISOString()
      }]);

    if (error) throw error;
  }
}