import { create } from 'zustand';
import { SubscriptionService } from '../services/SubscriptionService';

interface SubscriptionState {
  hasSubscription: boolean;
  isLoading: boolean;
  error: string | null;
  checkSubscription: () => Promise<void>;
}

export const useSubscriptionStore = create<SubscriptionState>((set) => ({
  hasSubscription: false,
  isLoading: true,
  error: null,
  checkSubscription: async () => {
    try {
      set({ isLoading: true, error: null });
      const subscriptionService = SubscriptionService.getInstance();
      const hasSubscription = await subscriptionService.hasActiveSubscription();
      set({ hasSubscription, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },
}));