import { supabase } from '../../../lib/supabase';

interface Question {
  questionText: string;
  choices: string[];
  answers: string[];
  explanation: string;
}

export function useQuestions() {
  const parseQuestions = (text: string): Question[] => {
    const questions: Question[] = [];
    const lines = text.split('\n').map(line => line.trim()).filter(line => line);
    let currentQuestion: Partial<Question> = {};
    let questionText = '';
    let isCollectingQuestion = false;
    
    for (let line of lines) {
      if (line.match(/^\d+\./)) {
        // Start of a new question
        if (Object.keys(currentQuestion).length > 0) {
          questions.push(currentQuestion as Question);
        }
        currentQuestion = {
          questionText: '',
          choices: [],
          answers: [],
          explanation: ''
        };
        isCollectingQuestion = true;
        questionText = line.replace(/^\d+\./, '').trim();
        continue;
      }

      if (isCollectingQuestion) {
        if (line.startsWith('A.')) {
          // End of question text, start of choices
          isCollectingQuestion = false;
          currentQuestion.questionText = questionText;
          currentQuestion.choices = [line.substring(2).trim()];
        } else {
          // Continue collecting question text
          questionText += '\n' + line;
        }
      } else if (line.startsWith('B.') || line.startsWith('C.') || 
                 line.startsWith('D.') || line.startsWith('E.') || 
                 line.startsWith('F.')) {
        currentQuestion.choices = currentQuestion.choices || [];
        currentQuestion.choices.push(line.substring(2).trim());
      } else if (line.toLowerCase().startsWith('answer:') || line.toLowerCase().startsWith('answers:')) {
        currentQuestion.answers = line
          .replace(/^answers?:/i, '') // Remove "Answer:" or "Answers:" case-insensitive
          .trim()
          .split(',')
          .map(answer => answer.trim());
      } else if (line.startsWith('Explanation:')) {
        currentQuestion.explanation = line.replace('Explanation:', '').trim();
        // Collect multi-line explanation
        let explanationLines = [];
        let i = lines.indexOf(line) + 1;
        while (i < lines.length && !lines[i].match(/^\d+\./) && !lines[i].startsWith('A.')) {
          if (lines[i].trim()) {
            explanationLines.push(lines[i].trim());
          }
          i++;
        }
        if (explanationLines.length > 0) {
          currentQuestion.explanation += '\n' + explanationLines.join('\n');
        }
        questions.push(currentQuestion as Question);
        currentQuestion = {};
      }
    }

    // Add the last question if it exists
    if (Object.keys(currentQuestion).length > 0 && currentQuestion.questionText) {
      questions.push(currentQuestion as Question);
    }

    return questions;
  };

  const uploadQuestions = async (topicId: string, questionsText: string) => {
    try {
      const questions = parseQuestions(questionsText);
      
      if (questions.length === 0) {
        throw new Error('No valid questions found');
      }

      // Create a new quiz
      const { data: quiz, error: quizError } = await supabase
        .from('quizzes')
        .insert([{
          title: 'New Quiz',
          description: 'Bulk uploaded questions',
          time_limit: 60,
          passing_score: 70
        }])
        .select()
        .single();

      if (quizError) throw quizError;

      // Insert questions with topic
      for (const q of questions) {
        const { data: question, error: questionError } = await supabase
          .from('questions')
          .insert([{
            quiz_id: quiz.id,
            topic_id: topicId,
            question_text: q.questionText,
            explanation: q.explanation
          }])
          .select()
          .single();

        if (questionError) throw questionError;

        const answers = q.choices.map((choice, index) => ({
            question_id: question.id,
            answer_text: choice,
            is_correct: q.answers.includes(String.fromCharCode(65 + index))
          }));

        const { error: answersError } = await supabase
          .from('answers')
          .insert(answers);

        if (answersError) throw answersError;
      }
    } catch (error) {
      console.error('Error uploading questions:', error);
      throw error;
    }
  };

  return { uploadQuestions };
}