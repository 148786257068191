import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Timer, Brain, Flag, ChevronLeft, ChevronRight, CheckCircle } from 'lucide-react';
import { QuizQuestion } from '../components/quiz/QuizQuestion';
import { QuizResult } from '../components/quiz/QuizResult';
import { formatTime } from '../utils/formatTime';

// Demo questions data
const demoQuestions = [
  {
    id: '1',
    question_text: 'Which of the following are key benefits of obtaining a tech certification?',
    explanation: 'Tech certifications provide multiple career benefits, from validating your skills to increasing job opportunities and earning potential.',
    topic_id: 'demo-topic',
    answers: [
      { id: '1a', answer_text: 'Higher salary potential', is_correct: true },
      { id: '1b', answer_text: 'Industry recognition', is_correct: true },
      { id: '1c', answer_text: 'Guaranteed job placement', is_correct: false },
      { id: '1d', answer_text: 'Professional credibility', is_correct: true }
    ]
  },
  {
    id: '2',
    question_text: 'What is the recommended first step when preparing for a certification exam?',
    explanation: 'Understanding exam objectives is crucial as it provides a roadmap for your study plan and ensures you cover all required topics.',
    topic_id: 'demo-topic',
    answers: [
      { id: '2a', answer_text: 'Take practice tests immediately', is_correct: false },
      { id: '2b', answer_text: 'Review exam objectives', is_correct: true },
      { id: '2c', answer_text: 'Join study groups', is_correct: false },
      { id: '2d', answer_text: 'Purchase study materials', is_correct: false }
    ]
  },
  {
    id: '3',
    question_text: 'Which of the following are common types of certification exam questions?',
    explanation: 'Modern certification exams use various question types to test both knowledge and practical skills.',
    topic_id: 'demo-topic',
    answers: [
      { id: '3a', answer_text: 'Multiple choice', is_correct: true },
      { id: '3b', answer_text: 'Performance-based', is_correct: true },
      { id: '3c', answer_text: 'Scenario-based', is_correct: true },
      { id: '3d', answer_text: 'Essay questions', is_correct: false }
    ]
  },
  {
    id: '4',
    question_text: 'What should you do the day before your certification exam?',
    explanation: 'Proper rest and preparation the day before the exam is crucial for optimal performance.',
    topic_id: 'demo-topic',
    answers: [
      { id: '4a', answer_text: 'Study all night', is_correct: false },
      { id: '4b', answer_text: 'Get plenty of rest', is_correct: true },
      { id: '4c', answer_text: 'Take multiple practice exams', is_correct: false },
      { id: '4d', answer_text: 'Learn new topics', is_correct: false }
    ]
  },
  {
    id: '5',
    question_text: 'Which strategies are recommended during the certification exam?',
    explanation: 'Effective time management and careful question reading are essential for exam success.',
    topic_id: 'demo-topic',
    answers: [
      { id: '5a', answer_text: 'Read questions carefully', is_correct: true },
      { id: '5b', answer_text: 'Skip difficult questions', is_correct: false },
      { id: '5c', answer_text: 'Manage time wisely', is_correct: true },
      { id: '5d', answer_text: 'Mark questions for review', is_correct: true }
    ]
  }
];

export function DemoQuiz() {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<Record<string, string[]>>({});
  const [quizComplete, setQuizComplete] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(600); // 10 minutes
  const [flaggedQuestions, setFlaggedQuestions] = useState<Set<number>>(new Set());

  React.useEffect(() => {
    if (!quizComplete && timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining(prev => {
          if (prev <= 1) {
            setQuizComplete(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeRemaining, quizComplete]);

  const handleAnswerSelect = (answerId: string) => {
    const currentQuestion = demoQuestions[currentQuestionIndex];
    const currentAnswers = selectedAnswers[currentQuestion.id] || [];
    const isSelected = currentAnswers.includes(answerId);

    setSelectedAnswers(prev => ({
      ...prev,
      [currentQuestion.id]: isSelected
        ? currentAnswers.filter(id => id !== answerId)
        : [...currentAnswers, answerId]
    }));
  };

  const toggleFlaggedQuestion = () => {
    setFlaggedQuestions(prev => {
      const newSet = new Set(prev);
      if (newSet.has(currentQuestionIndex)) {
        newSet.delete(currentQuestionIndex);
      } else {
        newSet.add(currentQuestionIndex);
      }
      return newSet;
    });
  };

  const calculateScore = () => {
    let correctCount = 0;
    demoQuestions.forEach(question => {
      const selectedAnswerIds = selectedAnswers[question.id] || [];
      const correctAnswerIds = question.answers
        .filter(answer => answer.is_correct)
        .map(answer => answer.id);

      const isCorrect = 
        selectedAnswerIds.length === correctAnswerIds.length &&
        selectedAnswerIds.every(id => correctAnswerIds.includes(id));

      if (isCorrect) correctCount++;
    });

    return Math.round((correctCount / demoQuestions.length) * 100);
  };

  const handleComplete = () => {
    setQuizComplete(true);
  };

  const handleRetry = () => {
    setCurrentQuestionIndex(0);
    setSelectedAnswers({});
    setQuizComplete(false);
    setTimeRemaining(600);
    setFlaggedQuestions(new Set());
  };

  if (quizComplete) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <QuizResult
            questions={demoQuestions}
            selectedAnswers={selectedAnswers}
            score={calculateScore()}
            onRetry={handleRetry}
            onNewQuiz={handleRetry}
          />
          <div className="text-center mt-8">
            <p className="text-gray-600 mb-4">
              Ready to try the full certification experience? Sign up for free!
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={() => navigate('/register')}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium"
              >
                Create Free Account
              </button>
              <button
                onClick={() => navigate('/certifications')}
                className="px-6 py-3 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 font-medium flex items-center justify-center"
              >
                <Brain className="h-5 w-5 mr-2" />
                Browse All Topics
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const currentQuestion = demoQuestions[currentQuestionIndex];
  const selectedAnswerIds = selectedAnswers[currentQuestion.id] || [];
  const isQuestionFlagged = flaggedQuestions.has(currentQuestionIndex);

  return (
    <div className="max-w-3xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="text-lg font-semibold">
            Question {currentQuestionIndex + 1} of {demoQuestions.length}
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={toggleFlaggedQuestion}
              className={`p-2 rounded-md ${
                isQuestionFlagged ? 'text-yellow-500' : 'text-gray-400'
              } hover:text-yellow-500`}
              title={isQuestionFlagged ? 'Unflag question' : 'Flag question for review'}
            >
              <Flag className="h-5 w-5" />
            </button>
            <div className={`flex items-center text-gray-600 ${
              timeRemaining <= 300 ? 'text-red-600' : ''
            }`}>
              <Timer className="h-5 w-5 mr-2" />
              {formatTime(timeRemaining)}
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <QuizQuestion
            question={currentQuestion}
            selectedAnswerIds={selectedAnswerIds}
            isQuestionFlagged={isQuestionFlagged}
            onAnswerSelect={handleAnswerSelect}
            onFlagQuestion={toggleFlaggedQuestion}
          />

          <div className="flex justify-between">
            <button
              onClick={() => setCurrentQuestionIndex(prev => prev - 1)}
              disabled={currentQuestionIndex === 0}
              className={`flex items-center px-4 py-2 rounded-md ${
                currentQuestionIndex === 0
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              <ChevronLeft className="h-5 w-5 mr-1" />
              Previous
            </button>
            {currentQuestionIndex === demoQuestions.length - 1 ? (
              <button
                onClick={handleComplete}
                className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Complete Quiz
                <CheckCircle className="h-5 w-5 ml-2" />
              </button>
            ) : (
              <button
                onClick={() => setCurrentQuestionIndex(prev => prev + 1)}
                className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Next
                <ChevronRight className="h-5 w-5 ml-1" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}