import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Shield, Award, Network, Cloud, ChevronDown, ChevronRight, Brain, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/auth';

interface Category {
  id: string;
  name: string;
  description: string;
}

interface Topic {
  id: string;
  name: string;
  description: string;
  category_id: string;
}

interface FlashcardCount {
  topic_id: string;
  count: number;
}

export function FlashcardTopics() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [flashcardCounts, setFlashcardCounts] = useState<FlashcardCount[]>([]);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [user]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        setError('Please sign in to view flashcard topics');
        return;
      }

      // Fetch categories
      const { data: categoriesData, error: categoriesError } = await supabase
        .from('categories')
        .select('*')
        .order('name');

      if (categoriesError) throw categoriesError;

      // Fetch topics
      const { data: topicsData, error: topicsError } = await supabase
        .from('topics')
        .select('*')
        .order('name');

      if (topicsError) throw topicsError;

      // Fetch flashcard counts per topic
      const { data: countsData, error: countsError } = await supabase
        .from('flashcards')
        .select('topic_id, count(*)', { count: 'exact' })
        .group('topic_id');

      if (countsError) throw countsError;

      setCategories(categoriesData || []);
      setTopics(topicsData || []);
      setFlashcardCounts(countsData || []);
      
      // Expand the first category by default
      if (categoriesData && categoriesData.length > 0) {
        setExpandedCategory(categoriesData[0].id);
      }
    } catch (error: any) {
      console.error('Error fetching data:', error);
      setError(error.message || 'Failed to load flashcard topics');
    } finally {
      setLoading(false);
    }
  };

  const getFlashcardCount = (topicId: string): number => {
    const count = flashcardCounts.find(c => c.topic_id === topicId);
    return count ? Number(count.count) : 0;
  };

  const getCategoryIcon = (categoryName: string) => {
    switch (categoryName.toLowerCase()) {
      case 'security':
        return <Shield className="h-6 w-6 text-blue-600" />;
      case 'cloud computing':
        return <Cloud className="h-6 w-6 text-purple-600" />;
      case 'networking':
        return <Network className="h-6 w-6 text-green-600" />;
      default:
        return <Award className="h-6 w-6 text-orange-600" />;
    }
  };

  const toggleCategory = (categoryId: string) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center">
          <div className="inline-flex items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            <span className="ml-3">Loading flashcard topics...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 text-red-700 p-4 rounded-md flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          <div>
            <p className="font-medium">{error}</p>
            {error === 'Please sign in to view flashcard topics' ? (
              <button
                onClick={() => navigate('/login', { state: { from: location } })}
                className="mt-2 text-sm text-red-600 hover:text-red-800"
              >
                Sign In
              </button>
            ) : (
              <p className="mt-1 text-sm">Please try again later.</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="space-y-8">
        <div>
          <h1 className="text-3xl font-bold text-gray-900">Flashcard Topics</h1>
          <p className="mt-2 text-lg text-gray-600">
            Choose a topic to study with flashcards
          </p>
        </div>

        <div className="space-y-6">
          {categories.map(category => (
            <div key={category.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <button
                onClick={() => toggleCategory(category.id)}
                className="w-full flex items-center justify-between p-6 hover:bg-gray-50"
              >
                <div className="flex items-center space-x-4">
                  {getCategoryIcon(category.name)}
                  <div className="text-left">
                    <h2 className="text-xl font-semibold text-gray-900">{category.name}</h2>
                    <p className="text-gray-600">{category.description}</p>
                  </div>
                </div>
                {expandedCategory === category.id ? (
                  <ChevronDown className="h-5 w-5 text-gray-400" />
                ) : (
                  <ChevronRight className="h-5 w-5 text-gray-400" />
                )}
              </button>

              {expandedCategory === category.id && (
                <div className="border-t border-gray-200">
                  <div className="divide-y divide-gray-200">
                    {topics
                      .filter(topic => topic.category_id === category.id)
                      .map(topic => {
                        const flashcardCount = getFlashcardCount(topic.id);
                        return (
                          <div
                            key={topic.id}
                            className="p-6 hover:bg-gray-50"
                          >
                            <div className="flex justify-between items-start">
                              <div className="flex-1">
                                <h3 className="text-lg font-medium text-gray-900">{topic.name}</h3>
                                <p className="mt-1 text-gray-600">{topic.description}</p>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                  <Brain className="h-4 w-4 mr-1" />
                                  {flashcardCount} flashcards available
                                </div>
                              </div>
                              <div className="ml-4">
                                <Link
                                  to={`/flashcards/${topic.id}`}
                                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
                                >
                                  <Brain className="h-4 w-4 mr-2" />
                                  Study Now
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}